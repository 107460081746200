import { Box } from '@valerahealth/ui-components'
import SearchGrid from './TaskSearchGrid'
import { TaskFormDialog } from './TaskFormDialog'

export default function TaskSearchGrid() {
  return (
    <Box sx={{ p: 2, height: '100%' }}>
      <SearchGrid />
      <TaskFormDialog />
    </Box>
  )
}
