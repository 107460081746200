import {
  AppointmentStatus,
  ServiceCategoryCode,
  ServiceTypeCode,
} from '@valerahealth/rtk-query'
import { theme } from '@valerahealth/ui-components'

const { accent, info } = theme.palette

export const serviceCategoryToColorMapping: Record<
  string,
  { backgroundColor: string; borderColor: string }
> = {
  [ServiceCategoryCode.Patient]: {
    backgroundColor: accent.green.mainBackground,
    borderColor: accent.green.main,
  },
  CASE_MANAGEMENT: {
    backgroundColor: accent.purple.darkBackground,
    borderColor: accent.purple.dark,
  },
  [ServiceCategoryCode.Toc]: {
    backgroundColor: accent.purple.darkBackground,
    borderColor: accent.purple.dark,
  },
  [ServiceCategoryCode.Admin]: {
    backgroundColor: accent.blue.main,
    borderColor: accent.blue.main,
  },
  [ServiceCategoryCode.Break]: {
    backgroundColor: accent.blue.dark,
    borderColor: accent.blue.dark,
  },
  [ServiceCategoryCode.OutOfOffice]: {
    backgroundColor: accent.blue.dark,
    borderColor: accent.blue.dark,
  },
  [ServiceCategoryCode.Consult]: {
    backgroundColor: accent.purple.darkBackground,
    borderColor: accent.purple.dark,
  },
  [ServiceCategoryCode.Cap]: {
    backgroundColor: accent.pink.darkBackground,
    borderColor: accent.pink.dark,
  },
  [ServiceCategoryCode.Group]: {
    backgroundColor: accent.green.darkBackground,
    borderColor: accent.green.dark,
  },
  [ServiceCategoryCode.Intake]: {
    backgroundColor: accent.orange.mainBackground,
    borderColor: accent.orange.main,
  },
  default: {
    backgroundColor: accent.green.mainBackground,
    borderColor: accent.green.main,
  },
}

export const getServiceCategoryColor = (code = '') => {
  return (
    serviceCategoryToColorMapping[code] ||
    serviceCategoryToColorMapping.default!
  )
}

export const serviceTypeToColorMapping: Record<
  string,
  {
    topColor: string
    bodyColor: string
    topContrastText?: string
    bodyContrastText?: string
  }
> = {
  /** deprecated service codes */
  PED: {
    topColor: accent.green.light,
    bodyColor: accent.green.main,
  },
  INC: {
    topColor: accent.green.light,
    bodyColor: accent.green.main,
  },
  F20: {
    topColor: accent.pink.main,
    bodyColor: accent.green.main,
  },
  TOC: {
    topColor: accent.pink.light,
    bodyColor: accent.purple.dark,
  },
  CAP: {
    topColor: accent.pink.dark,
    bodyColor: accent.green.main,
  },
  CAP_INT: {
    topColor: accent.green.light,
    bodyColor: accent.green.main,
  },
  DIP: {
    topContrastText: info.dark,
    topColor: accent.blue.light,
    bodyColor: accent.green.main,
  },
  /** end deprecated service codes */
  [ServiceTypeCode.Adm]: {
    topColor: '#4D5592',
    bodyColor: '#4D5592',
  }, // Admin Hours
  [ServiceTypeCode.Brk]: {
    topColor: '#4D5592',
    bodyColor: '#4D5592',
  }, // Break Hours
  [ServiceTypeCode.Con]: {
    topColor: accent.pink.light,
    bodyColor: accent.purple.dark,
  }, // Consult Hours
  [ServiceTypeCode.Dbt]: {
    topColor: '#B6A7CA',
    bodyColor: accent.green.main,
  },
  [ServiceTypeCode.DbtInt]: {
    topColor: '#B6A7CA',
    bodyColor: accent.green.main,
  },
  [ServiceTypeCode.Emdr]: {
    topColor: 'rgba(172, 166, 136, 1)',
    bodyColor: accent.green.main,
  },
  [ServiceTypeCode.EmdrInt]: {
    topColor: 'rgba(172, 166, 136, 1)',
    bodyColor: accent.green.main,
  },
  [ServiceTypeCode.F30]: {
    topColor: '#DB9B99',
    bodyColor: accent.green.main,
  },
  [ServiceTypeCode.F45]: {
    topColor: '#DB9B99',
    bodyColor: accent.green.main,
  },
  [ServiceTypeCode.F60]: {
    topColor: '#DB9B99',
    bodyColor: accent.green.main,
  },
  [ServiceTypeCode.Grp]: {
    topColor: 'rgba(152, 208, 222, 1)',
    bodyColor: '#00695C',
  },
  [ServiceTypeCode.GrpInt]: {
    topColor: 'rgba(152, 208, 222, 1)',
    bodyColor: '#00695C',
  },
  [ServiceTypeCode.Int]: {
    topColor: '#F79B4F',
    bodyColor: accent.green.main,
  },
  [ServiceTypeCode.Int45]: {
    topColor: accent.green.main,
    bodyColor: accent.green.main,
  },
  [ServiceTypeCode.LocInt]: {
    topColor: accent.orange.main,
    bodyColor: accent.orange.main,
  },
  [ServiceTypeCode.Medrec]: {
    topColor: accent.blue.light,
    bodyColor: accent.green.main,
  },
  [ServiceTypeCode.PsyInt]: {
    topColor: 'rgba(71, 106, 148, 1)',
    bodyColor: accent.green.main,
  },
  [ServiceTypeCode.PsyCapInt]: {
    topColor: 'rgba(71, 106, 148, 1)',
    bodyColor: accent.green.main,
  },
  [ServiceTypeCode.PsyInc]: {
    topColor: 'rgba(71, 106, 148, 1)',
    bodyColor: accent.green.main,
  },
  [ServiceTypeCode.PsyF20]: {
    topColor: 'rgba(177, 139, 219, 1)',
    bodyColor: accent.green.main,
  },
  [ServiceTypeCode.PsyF30]: {
    topColor: 'rgba(177, 139, 219, 1)',
    bodyColor: accent.green.main,
  },
  [ServiceTypeCode.PsyF45]: {
    topColor: 'rgba(177, 139, 219, 1)',
    bodyColor: accent.green.main,
  },
  [ServiceTypeCode.PsyF60]: {
    topColor: 'rgba(177, 139, 219, 1)',
    bodyColor: accent.green.main,
  },
  [ServiceTypeCode.PsyTrf]: {
    topColor: '#A0BE61',
    bodyColor: accent.green.main,
  },
  [ServiceTypeCode.PsyTocInt]: {
    topColor: 'rgba(247, 155, 79, 1)',
    bodyColor: accent.purple.darkBackground,
  },
  [ServiceTypeCode.TocInt]: {
    topColor: '#8F7158',
    bodyColor: accent.purple.dark,
  },
  [ServiceTypeCode.Trf]: {
    topColor: '#7D9846',
    bodyColor: accent.green.main,
  },
  default: {
    topColor: accent.green.light,
    bodyColor: accent.green.main,
  },
}

export const getServiceTypeColor = (code?: string) => {
  let palette = serviceTypeToColorMapping.default
  if (code && code in serviceTypeToColorMapping)
    palette = serviceTypeToColorMapping[code]

  return {
    topContrastText: info.contrastText,
    bodyContrastText: info.contrastText,
    ...palette,
  }
}

export const getAppointmentStatusColor = (status: AppointmentStatus) => {
  switch (status) {
    case AppointmentStatus.Fulfilled:
      return 'success'
    case AppointmentStatus.Rescheduled:
    case AppointmentStatus.Proposed:
    case AppointmentStatus.Pending:
      return 'warning'
    case AppointmentStatus.Canceled:
    case AppointmentStatus.Canceled_24:
    case AppointmentStatus.Canceled_48:
    case AppointmentStatus.Noshow:
      return 'error'
    default:
      return undefined
  }
}
