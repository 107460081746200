import React from 'react'
import { format } from '@valerahealth/ui-components/utils/date'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import CallIcon from '../../images/icn_call.svg'
import LocationIcon from '../../images/icn_location.svg'
import EligibilityIcon from '../../images/icn_eligibility.svg'
import AssessmentIcon from '../../images/icn_assesment_answer.svg'
import TextIcon from '../../images/icn_text.svg'
import ScoreIcon from '../../images/icn_assesment_score.svg'
import EyeIcon from '../../images/eye.svg'
import dStrings from '../../strings.json'
import { dateFormatEnum, domainEventEnum, frequency, trackType } from './enums'
import { getProfileName } from './treatments'
import { contries } from './contriesList'
import CaseloadHold from '../../images/caseload_status_hold.svg'
import CaseloadOpen from '../../images/caseload_status_open.svg'
import CaseloadClose from '../../images/caseload_status_close.svg'

export const MESSAGES_LENGTH = 30
export const TOKEN_WILL_EXPIRED_IN_MINUTES = 10

export const isIOS =
  !!navigator.platform && /iPad|iPhone|iPod|Mac/.test(navigator.platform)

export const getListByIdAndAllIds = (list, idKey = 'id') => {
  const obj = {
    byId: {},
    allIds: [],
  }

  for (let index = 0; index < list.length; index++) {
    const element = list[index]
    obj.byId[element[idKey]] = element
    obj.allIds.push(element[idKey])
  }

  return obj
}

export const getHour = (hour, minute) => {
  let txt = `${!~[0, 12].indexOf(hour) ? hour % 12 : 12}:`
  txt += minute >= 10 ? minute : `0${minute}`
  txt += hour >= 12 ? 'PM' : 'AM'

  return txt
}

export const days = [
  dStrings.sunday,
  dStrings.monday,
  dStrings.tuesday,
  dStrings.wednesday,
  dStrings.thursday,
  dStrings.friday,
  dStrings.saturday,
]

export const daysLetters = {
  0: 'S',
  1: 'M',
  2: 'T',
  3: 'W',
  4: 'T',
  5: 'F',
  6: 'S',
}

export const getDayNum = (i) => {
  const num = typeof i === 'number' ? i : parseInt(i)

  const j = num % 10
  const k = num % 100

  if (j === 1 && k !== 11) {
    return `${num}st`
  }
  if (j === 2 && k !== 12) {
    return `${num}nd`
  }
  if (j === 3 && k !== 13) {
    return `${num}rd`
  }
  return `${num}th`
}

export const getScheduleSentence = (track) => {
  try {
    if (!track || !track.schedule || !track.schedule.frequency)
      return dStrings.clickSetSchedule

    let txt = ''
    if (!track.isSingleItem) {
      txt += dStrings.itemAreSent
    } else {
      txt += dStrings.itemIsSent
    }

    if (!track.isSingleItem || track.type === trackType.CYCLIC) {
      switch (track.schedule.frequency) {
        case frequency.DAILY:
          if (track.schedule.interval > 1) {
            txt += dStrings.everyDays.replace(
              '${count}',
              track.schedule.interval,
            )
          } else {
            txt += dStrings.everyDay
          }
          break
        case frequency.WEEKLY:
          if (track.schedule.interval > 1) {
            txt += dStrings.everyWeeks
              .replace('${count}', track.schedule.interval)
              .replace(
                '${weekday}',
                track.schedule.weekDays.map((d) => dStrings[d]).join(', '),
              )
          } else {
            txt += dStrings.everyWeek.replace(
              '${weekday}',
              track.schedule.weekDays.map((d) => dStrings[d]).join(', '),
            )
          }
          break
        case frequency.MONTHLY:
          if (track.schedule.interval > 1) {
            txt += dStrings.everyMonths
              .replace('${count}', track.schedule.interval)
              .replace(
                '${monthday}',
                track.schedule.monthDays.map((d) => getDayNum(d)).join(', '),
              )
          } else {
            txt += dStrings.everyMonth.replace(
              '${monthday}',
              track.schedule.monthDays.map((d) => getDayNum(d)).join(', '),
            )
          }
          break
        default:
          break
      }
    } else if (track.schedule.startDate) {
      txt += dStrings.onDate.replace(
        '${date}',
        format(new Date(track.schedule.startDate), dateFormatEnum.FULL_DATE),
      )
    }

    if (track.schedule.hours && track.schedule.hours.length) {
      txt += dStrings.atHour.replace(
        '${hour}',
        getHour(
          track.schedule.hours[0],
          track.schedule.minutes && track.schedule.minutes.length
            ? track.schedule.minutes[0]
            : 0,
        ),
      )
    }

    if (track.isSingleItem) {
      if (track.schedule.startDate)
        txt += dStrings.startsOn.replace(
          '${date}',
          format(new Date(track.schedule.startDate), dateFormatEnum.FULL_DATE),
        )
      if (track.schedule.endDate)
        txt += dStrings.endOn.replace(
          '${date}',
          format(new Date(track.schedule.endDate), dateFormatEnum.FULL_DATE),
        )
      else if (track.schedule.count) {
        if (track.schedule.count > 1)
          txt += dStrings.endAfterOccurrences.replace(
            '${count}',
            track.schedule.count,
          )
        else if (track.isSingleItem) txt += dStrings.doNotRepeat
      }
    }

    if (!track.isSingleItem) {
      if (track.type === trackType.ONETIME) {
        txt += dStrings.listNotRepeats
      } else {
        txt += dStrings.listRepeats
      }
    }

    return txt
  } catch (error) {
    return ''
  }
}

/**
 *
 * @param {Object} tracks 
 * 
 * @returns {Object} {
    byId: obj,
    multipleItemsIds: multipleItems,
    singleItemsIds: singleItems
  }
 */
export const getTracksByItems = (tracks) => {
  const multipleItems = []
  const singleItems = []
  const obj = {}
  for (let index = 0; index < tracks.length; index++) {
    if (!tracks[index].isSingleItem) multipleItems.push(tracks[index].id)
    else singleItems.push(tracks[index].id)

    obj[tracks[index].id] = tracks[index]
  }

  const t = {
    byId: obj,
    multipleItemsIds: multipleItems,
    singleItemsIds: singleItems,
  }

  return { tracks: t }

  /*
  {
    tracks: {
      byId: {
        123: {},
        456: {}
      },
      allIds: [123,456],
      singleItemsIds: [456]    
      multipleItemsIds: [123]    
    }
  }
  */
}

export const getToolTemplateTitle = (toolsTemplates, item) => {
  const toolTemplate =
    toolsTemplates[item.type] || toolsTemplates[item.type.split('_')[0]]

  if (toolTemplate && toolTemplate.type === 'select') {
    try {
      return toolTemplate.list &&
        toolTemplate.list.allIds &&
        toolTemplate.list.allIds.length
        ? toolTemplate.list.byId[item.info.templateId][toolTemplate.key]
        : ''
    } catch (error) {
      console.log('toolTemplate: ', toolTemplate)
      console.log('item: ', item)
    }
  } else {
    return item.info.text
  }

  // TODO: remove string
  // debugger;
  return 'error occurred'
}

export const sortObjectsListByKey = (key, a, b) => {
  const keyA = a[key]
  const keyB = b[key]
  if (
    (keyA === null || keyA === undefined) &&
    (keyB === null || keyB === undefined)
  ) {
    return 0
  }
  if (keyB === null || keyB === undefined) return -1
  if (keyA === null || keyA === undefined) return 1

  if (keyA < keyB) return -1
  if (keyA > keyB) return 1
  return 0
}

const getIndicesOf = (str, searchStr, caseSensitive) => {
  const searchStrLen = searchStr.length
  if (searchStrLen == 0) {
    return []
  }
  let startIndex = 0
  let index
  const indices = []
  if (!caseSensitive) {
    str = str.toLowerCase()
    searchStr = searchStr.toLowerCase()
  }
  while ((index = str.indexOf(searchStr, startIndex)) > -1) {
    indices.push(index)
    startIndex = index + searchStrLen
  }
  return indices
}

export const highlightWords = (text, findClass, wordsToSearch) => {
  let wordsIndex = []
  for (let i = 0; i < wordsToSearch.length; i++) {
    const indexes = getIndicesOf(text, wordsToSearch[i])
    if (indexes.length) {
      for (let y = 0; y < indexes.length; y++) {
        wordsIndex.push({
          from: indexes[y],
          to: indexes[y] + wordsToSearch[i].length,
        })
      }
    }
  }

  if (!wordsIndex.length) return text
  wordsIndex.sort((a, b) => {
    return a.from < b.from ? -1 : 1
  })

  let flag = false
  do {
    flag = false
    for (let y = 0; y < wordsIndex.length; y++) {
      if (wordsIndex[y + 1] && wordsIndex[y].to >= wordsIndex[y + 1].from) {
        wordsIndex[y].to = wordsIndex[y + 1].to
        wordsIndex[y + 1].remove = true
        flag = true
      }

      wordsIndex = wordsIndex.filter((w) => !w.remove)
    }
  } while (flag)

  let index = 0
  const result = (
    <>
      {wordsIndex.map((word, i) => {
        const content = (
          <span key={i}>
            {index !== word.from ? text.substring(index, word.from) : ''}
            <mark className={findClass}>
              {text.substring(word.from, word.to)}
            </mark>
            {i === wordsIndex.length - 1 &&
              text.substring(word.to, text.length)}
          </span>
        )

        index = word.to
        return content
      })}
    </>
  )

  return result
}

export const getIcon = (icon) => {
  try {
    const Icon = icon
    return <Icon />
  } catch (error) {
    return <div />
  }
}

export const getTimelineByDay = (data, plans) => {
  data.sort(function (a, b) {
    return new Date(a.ts) - new Date(b.ts)
  })

  const obj = {}
  const daysObj = {}
  let dayFlag = 1
  for (let i = 0; i < data.length; i++) {
    const key = format(new Date(data[i].ts), 'LLL d, yyyy')
    if (!obj[key]) {
      obj[key] = []
      daysObj[key] = dayFlag
      dayFlag++
    }

    let isSingleItem = false
    try {
      const planId = data[i].planId || data[i].item.context.id
      isSingleItem =
        plans && plans[planId].tracks.byId[data[i].trackId].isSingleItem
    } catch (error) {
      //
    }

    obj[key].push({
      ...data[i],
      isSingleItem,
    })
  }

  return { days: daysObj, data: obj }
}

export const getAlertIcon = (type) => {
  switch (type) {
    case 'treatment_eligibility':
      return <EligibilityIcon />
    case 'assessment_score':
      return <ScoreIcon />
    case 'assessment_answer':
    case 'incomplete_assessment':
      return <AssessmentIcon />
    case 'call_emergency_line':
      return <CallIcon />
    case 'text_crisis_hotline':
      return <TextIcon />
    case 'unchanged_location':
      return <LocationIcon />

    default:
      return <EyeIcon />
  }
}

export const downloadContent = (url, title) => {
  const link = document.createElement('a')
  link.href = url
  link.download = title
  link.click()
}

export const openContent = (url) => {
  const win = window.open(url, '_blank')
  if (win) {
    win.focus()
  } else {
    downloadContent(url, 'item')
  }
}

export const checkEmail = (email) => {
  /* eslint no-useless-escape: "off" */
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export function DropdownIndicator() {
  return <span className="arrow-down dropdown-indicator" />
}

export const getCustomStyles = (obj) => {
  let height
  let width
  let control
  let indicatorsContainer
  if (obj) {
    height = obj.height
    width = obj.width
    control = obj.control
    indicatorsContainer = obj.indicatorsContainer
  }

  return {
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor:
        !isSelected && !isFocused
          ? '#ffffff'
          : isFocused
          ? '#e7ebed85'
          : '#E7EBED',
      color: '#54585D',
      cursor: 'pointer',
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: '#bbbbba !important',
      borderRadius: '5px',
      minHeight: height || '36px',
      height: height || '36px',
      minWidth: width || '240px',
      boxShadow: state.isFocused ? null : null,
      cursor: 'pointer',
      width,
      ...control,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: height || '36px',
      padding: '0 6px',
      width,
    }),
    groupHeading: (provided, state) => ({
      ...provided,
      color: '#5F6368',
      fontFamily: 'Avenir',
      fontSize: '13px',
      fontHeight: '300',
      letterSpacing: '0',
      lineHeight: '18px',
    }),
    input: (provided, state) => ({
      ...provided,
      margin: '0px',
      width,
    }),
    indicatorSeparator: (state) => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: height || '36px',
      ...indicatorsContainer,
    }),
    menu: (provided, state) => ({
      ...provided,
      marginTop: 0,
    }),
  }
}
export const getFormattedItemTs = (message, from) => {
  if (message.attachment.item) {
    return format(
      new Date(message.attachment.item.ts),
      'EEEE, LLL d, hh:mm aaa',
    )
  }

  if (message.ts) {
    return format(new Date(message.ts), 'EEEE, LLL d, hh:mm aaa')
  }

  console.error(
    `Problem in attachement from ${from} of item message ${JSON.stringify(
      message,
    )}.`,
  )
  return ''
}

export const appointmentMessage = (data) => {
  switch (data.domainEvent) {
    case domainEventEnum.PATIENT_REQUEST_SENT:
      return dStrings.patientRequestAppointment
    case domainEventEnum.APPOINTMENT_ACCEPTED:
      return `Appointment invite accepted - ${data.attachment.item.description}`
    case domainEventEnum.APPOINTMENT_DECLINED:
      return `Appointment invite declined - ${data.attachment.item.description}`
    case domainEventEnum.APPOINTMENT_RESCHEDULED:
      return `${dStrings.rescheduleRequest} - {${data.attachment.item.custom.providerName}}`

    default:
      return 'NEW NOTIFICATIONS'
  }
}

export const getNotificationMessage = (data) => {
  if (data.message) return data.message

  if (data.attachment?.category === 'appointment')
    return appointmentMessage(data)

  if (getIsCTMMessage(data.domainEvent)) {
    switch (data.domainEvent) {
      case 'ctm_added':
        return dStrings.ctmJoinTeam.replace('${name}', data.attachment.title)
      case 'ctm_removed':
        return dStrings.ctmLeaveTeam.replace('${name}', data.attachment.title)
      case 'pctm_changed':
        return dStrings.newPctm.replace('${name}', data.attachment.title)

      default:
        break
    }
  }

  return data.attachment.title
}

export const searchInCaseload = (obj, text) => {
  const result = obj.allIds.filter((id) => {
    if (!obj.byId[id]) {
      console.error(
        `obj.byId[id] error. State.pendind: ${JSON.stringify(obj)}, id: ${id}`,
      )

      return 0
    }

    return ~getProfileName(obj.byId[id].profile)
      .toLowerCase()
      .indexOf(text.toLowerCase())
  })

  return result
}

export const getIsCTMMessage = (text) => {
  return ['pctm_changed', 'ctm_added', 'ctm_removed'].indexOf(text) !== -1
}

export const getIsChatMessage = (text) => {
  return (
    ['treatment_chat_enabled', 'treatment_chat_disabled'].indexOf(text) !== -1
  )
}

export const isVideoMessage = (attachment) => {
  return attachment && attachment.category === 'telehealth'
}

export const isNewTemplate = (id) => {
  return id.indexOf('new_planTemplate') !== -1
}

export const standartTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

export const getUrlFromObject = (url, obj) => {
  Object.keys(obj).forEach((element) => {
    if (Array.isArray(obj[element])) {
      const list = obj[element]
      for (let i = 0; i < list.length; i++) {
        const temp = list[i]
        url += `${element}=${
          typeof temp === 'string'
            ? encodeURIComponent(temp)
            : encodeURI(JSON.stringify(temp))
        }&`
      }
    } else if (obj[element] instanceof Date) {
      url += `${element}=${obj[element].toISOString()}&`
    } else if (typeof obj[element] === 'object') {
      url += `${element}=${encodeURI(JSON.stringify(obj[element]))}&`
    } else if (obj[element] !== undefined) {
      url += `${element}=${encodeURIComponent(obj[element])}&`
    }
  })

  return url
}

export const getCaseloadStatusIcon = (status) => {
  if (status === 'open') return <CaseloadOpen />
  if (status === 'closed') return <CaseloadClose />

  return <CaseloadHold />
}

export const getLicenseTypeString = (type) => {
  if (['NP', 'MD'].indexOf(type) === -1) {
    return `${type} ${dStrings.therapist}`
  }

  return `${type} ${dStrings.prescriber}`
}

export const dateWithTimeZone = (oDate, timeZone) => {
  if (!oDate) return

  const year = oDate.getFullYear()
  const month = oDate.getMonth()
  const day = oDate.getDate()
  const hour = oDate.getHours()
  const minute = oDate.getMinutes()
  const second = oDate.getSeconds()

  const date = new Date(Date.UTC(year, month, day, hour, minute, second))

  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }))
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone }))
  const offset = utcDate.getTime() - tzDate.getTime()

  date.setTime(date.getTime() + offset)

  return date
}

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()

    return <Component {...props} router={{ location, navigate, params }} />
  }

  return ComponentWithRouterProp
}

export function generateID() {
  return `id${Math.random().toString(36).substr(2, 9)}`
}

// if variable=false, should return false
export const isEmpty = (variable) => {
  return (
    variable === null ||
    variable === undefined ||
    (typeof variable === 'string' && variable.trim() === '')
  )
}
