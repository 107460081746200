import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Grid, MenuItem, ProgramTagSelect } from '@valerahealth/ui-components'
import { useTranslation } from '@valerahealth/ui-translation'
import { PatientStatus } from '@valerahealth/rtk-query'
import { useDispatch, useSelector } from 'react-redux'
import { Alert } from '@valerahealth/ui-components/base'
import {
  Combobox,
  Select,
  ControlledTextField,
} from '@valerahealth/ui-components/form'
import { loadLanguages } from '../../redux/actions/globalActions'
import { loadProgramTags } from '../../redux/actions/programActions'
import { getTreatment } from '../utilities/treatments'
import { formControlProps } from './utilities'

const OnBoarding = () => {
  const { t } = useTranslation()
  const [displayAlert, setDisplayAlert] = useState(false)
  const dispatch = useDispatch()
  const tags = useSelector((state) => state.program?.tags)
  const languages = useSelector((state) => state.global.languages)
  const treatment = useSelector((state) => getTreatment(state) || {})
  const [searchParams, setSearchParams] = useSearchParams()
  const autoFocusStatus = searchParams.get('autoFocusStatus')

  useEffect(() => {
    if (!tags) dispatch(loadProgramTags())
    if (!languages) dispatch(loadLanguages())
  }, [])

  return (
    <Grid container spacing={3} flexDirection="row">
      <Grid xs={12} item>
        <Select
          fullWidth
          autoFocus={!!autoFocusStatus}
          onChange={(e) => {
            setDisplayAlert(e.target.value !== treatment.status)
          }}
          formControlProps={formControlProps}
          label={t('patientStatus')}
          name="status"
        >
          {Object.values(PatientStatus).map((v) => (
            <MenuItem key={v} value={v}>
              {t(`PatientStatus.${v}`)}
            </MenuItem>
          ))}
        </Select>
        {displayAlert && (
          <Alert sx={{ mt: 2, width: '100%' }} severity="warning">
            {t('changeStatusWarning')}
          </Alert>
        )}
      </Grid>
      <Grid item container xs={12} spacing={3}>
        <Grid item xs={6}>
          <ControlledTextField
            disabled
            fullWidth
            label={t('baselinePHQ9')}
            name="phqScore"
          />
        </Grid>
        <Grid item xs={6}>
          <ControlledTextField
            disabled
            fullWidth
            label={t('baselineGAD')}
            name="gad7Score"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} spacing={3}>
        <ProgramTagSelect fullWidth label={t('tags')} name="diagnosis" />
      </Grid>
      <Grid item container spacing={3} xs={12}>
        <Grid item xs={6}>
          <Combobox
            fullWidth
            label={t('languages')}
            name="languages"
            multiple
            disableCloseOnSelect
            textFieldProps={{ fullWidth: true }}
            options={languages?.map((l) => l.code) || []}
            getOptionLabel={(l) => {
              return languages?.find((a) => a.code === l)?.display
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            formControlProps={formControlProps}
            label={t('preferredLanguage')}
            name="preferredLanguage"
          >
            {(languages || []).map((language) => (
              <MenuItem key={language.code} value={language.code}>
                {language.display}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default OnBoarding
