import { createSelector } from '@reduxjs/toolkit'
import { careManagerApi, configApi } from '@valerahealth/rtk-query'
import { type RootState } from './store'

export const programIdSelector = (state: RootState) =>
  state.auth.session?.user.programId || ''

export const programSettingsSelector = createSelector(
  programIdSelector,
  (state: RootState) => state,
  (id, state) => careManagerApi.endpoints.getProgramSettings.select(id)(state),
)

export const usrSettingsSelector =
  careManagerApi.endpoints.getUserSettings.select()

export const configApiProgramSettingsSelector =
  configApi.endpoints.getProgramSettings.select()

export const configProgramSettingsSelector = (state: RootState) =>
  configApiProgramSettingsSelector(state).data?.getProgramSettings

export const isWellnessSelector = createSelector(
  configProgramSettingsSelector,
  (settings) => !!settings?.flags?.isWellness,
)
