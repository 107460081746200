import { useMemo } from 'react'
import {
  Stack,
  Typography,
  Button,
  useConfirmationDialog,
  useNotify,
  WorkflowSidebarContent,
  WorkflowSidebarActions,
  LinearProgress,
} from '@valerahealth/ui-components'
import { translateApiError } from '@valerahealth/ui-components/utils'
import {
  type Appointment,
  type ScheduleFragment,
  ResourceType,
  schedulingApi,
} from '@valerahealth/rtk-query'
import ScheduleSummary from './ScheduleSummary'
import { useTranslation } from '../locales'
import { useGetMutationPermissionsForPMCalendar } from '../hooks/useGetMutationPermissions'
import { useReduxDispatch, actions, ScheduleListView } from '../reducer'

export type OnEditFn = (arg: ScheduleFragment | Appointment) => void

/** trying to keep any dependancy on react-router out of components */
export type ScheduleListProps = Omit<ScheduleListView, 'type'>

const { useDeleteScheduleMutation } = schedulingApi

function Schedules({ code, provider }: ScheduleListProps) {
  const notify = useNotify()

  const [deleteSchedule] = useDeleteScheduleMutation()
  const { t } = useTranslation()
  const dispatch = useReduxDispatch()
  const { schedules, ...scheduleStatus } =
    schedulingApi.useGetSchedulesByProviderIdQuery(
      {
        id: provider._id,
      },
      {
        selectFromResult: ({ data, isFetching, isError }) => ({
          isFetching,
          isError,
          schedules: data?.searchSchedules?.results || [],
        }),
      },
    )
  const filteredSchedules = useMemo(
    () => schedules.filter((s) => s.serviceCategory.code === code),
    [schedules, code],
  )

  const { canUpdateSchedule: canUpdate, canDeleteSchedule: canDelete } =
    useGetMutationPermissionsForPMCalendar(provider._id)
  const { ConfirmationDialog, confirm } = useConfirmationDialog()

  const handleDelete = async (id: string) => {
    const recordToDelete = schedules.find((d) => d._id === id)!

    const serviceCategory = t(
      `ServiceCategoryCode.${recordToDelete.serviceCategory.code}`,
    )
    const confirmed = await confirm({
      header: t('SchedulingSidebar.confirmDeleteHeader', { serviceCategory }),
      body: t('SchedulingSidebar.confirmDeleteBody', { serviceCategory }),
    })

    if (confirmed) {
      const res = await deleteSchedule({ id })
      if ('error' in res) {
        notify({
          message: translateApiError(
            t,
            res.error,
            t('api.schedule.deleteFailure'),
          ),
          severity: 'error',
        })
      } else {
        notify({
          message: t('api.schedule.deleteSuccess'),
          severity: 'success',
        })
      }
    }
  }

  return (
    <Stack>
      {scheduleStatus.isFetching && <LinearProgress />}
      {filteredSchedules.map((schedule) => {
        const {
          _id,
          reoccuranceTemplate: {
            planningHorizon: { startDate, endDate },
          },
          actors,
        } = schedule

        const locations =
          actors
            ?.filter(
              ({ type, display }) => type === ResourceType.Location && display,
            )
            ?.map((l) => l.display || '') || []

        return (
          <ScheduleSummary
            key={_id}
            startDate={startDate!}
            endDate={endDate}
            locations={locations}
            onEdit={
              canUpdate
                ? () =>
                    dispatch(
                      actions.openView({
                        type: 'scheduleForm',
                        mode: 'edit',
                        schedule,
                        provider,
                      }),
                    )
                : null
            }
            onDelete={canDelete ? () => handleDelete(schedule._id) : null}
          />
        )
      })}
      <ConfirmationDialog
        confirmButtonColor="error"
        confirmLabel={t('delete')}
      />
    </Stack>
  )
}

export default function ScheduleList(props: ScheduleListProps) {
  const { provider, code } = props
  const { t } = useTranslation()
  const dispatch = useReduxDispatch()

  const { canCreateSchedule } = useGetMutationPermissionsForPMCalendar(
    provider._id,
  )
  return (
    <>
      <WorkflowSidebarContent
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Typography variant="h6">
          {t('SchedulingSidebar.scheduledListTitle', {
            serviceCategory: t(`ServiceCategoryCode.${code}`),
          })}
        </Typography>
        <Stack sx={{ flex: '1' }}>
          <Schedules {...props} />
        </Stack>
      </WorkflowSidebarContent>
      <WorkflowSidebarActions>
        {canCreateSchedule && (
          <Button
            onClick={() => {
              dispatch(
                actions.openView({
                  type: 'scheduleForm',
                  mode: 'add',
                  code,
                  provider,
                }),
              )
            }}
            sx={{
              ml: 1,
            }}
            color="primary"
          >
            {t('SchedulingSidebar.addNamedSchedule', {
              serviceCategory: t(`ServiceCategoryCode.${code}`),
            })}
          </Button>
        )}
      </WorkflowSidebarActions>
    </>
  )
}
