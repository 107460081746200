import { Fragment, useState } from 'react'
import { GlobalItem } from 'redux/reducers/legacyRootState'
import {
  CheckCircleOutlined,
  FileDownload,
} from '@valerahealth/ui-components/icons'
import {
  AnsweredQuestionnaireQuestion,
  AnsweredQuestionnaireQuestionAnswer,
  AssignedQuestionnaire,
  Questionnaire,
  QuestionnaireQuestion,
  QuestionnaireQuestionAnswer,
  careManagerApi,
} from '@valerahealth/rtk-query'
import {
  Box,
  CenteredSpinner,
  Chip,
  CircularProgress,
  IconButton,
  Typography,
  useNotify,
} from '@valerahealth/ui-components'
import { format } from '@valerahealth/ui-components/utils/date'
import LABELS from 'locales/en'
import { useReduxDispatch, useReduxSelector } from 'redux/store'
import { dateFormatEnum } from 'components/utilities/enums'
import ItemPopup from './ItemPopup'
import { downloadContent } from '../utilities'

type Props = {
  item: GlobalItem
}

const getTitle = (data: Questionnaire) =>
  `${data.name}${data.alias ? ` (${data.alias})` : ''}`

const QuestionnaireAnswers = ({
  question,
}: {
  question: AnsweredQuestionnaireQuestion | QuestionnaireQuestion
}) => {
  const a = question.answers?.filter(
    (
      a: QuestionnaireQuestionAnswer | AnsweredQuestionnaireQuestionAnswer,
    ): a is AnsweredQuestionnaireQuestionAnswer =>
      'markedSelected' in a && !!a.markedSelected,
  )
  if (!a.length) return null

  const text =
    a.length === 1
      ? a[0]!.content || a[0]!.text
      : a.map((r) => <div key={r.id}>{r.text}</div>)

  return (
    <>
      {'score' in question && typeof question.score === 'number' && (
        <Chip
          sx={{ gridColumnStart: 3, justifySelf: 'center' }}
          size="small"
          color="primary"
          label={question.score}
        />
      )}
      <Box sx={{ gridColumnStart: 4 }}>{text}</Box>
    </>
  )
}

export default function ViewConsentForm({ item }: Props) {
  const dispatch = useReduxDispatch()
  const notify = useNotify()
  const treatmentId = useReduxSelector(
    (state) => state.treatmentRoom.selectedId || '',
  )
  const patientId = useReduxSelector(
    (state) =>
      state.treatmentRoom.byId[state.treatmentRoom.selectedId || '']
        ?.patientId!,
  )
  const questionnaireRes = careManagerApi.useGetQuestionnaireQuery(
    // code triggering global items is super messy and it mixes up the IDs, often passing the id as templateId
    { id: item.info.templateId || item.info.id },
    { skip: item.assigned },
  )
  const assignedQuestionnaireRes =
    careManagerApi.useGetTreatmentQuestionnaireQuery(
      {
        // item does not always consistently have treatmentId, fallback to the selected treatment id if not present event though its not a great pattern
        treatmentId,
        assignedQuestionnaireId: item.info.id,
      },
      { skip: !item.assigned || !treatmentId },
    )

  const [exportIsLoading, setExportIsLoading] = useState(false)

  //if we jump to another treatment id we dont want to show the old treatments cached response
  const isLoading =
    assignedQuestionnaireRes.isFetching || questionnaireRes.isFetching
  const error = assignedQuestionnaireRes.error || questionnaireRes.error
  const assignedQuestionnaire = assignedQuestionnaireRes.data
  const data: AssignedQuestionnaire | Questionnaire | undefined =
    assignedQuestionnaireRes.data || questionnaireRes.data

  return (
    <ItemPopup
      title={data ? getTitle(data) : 'Loading...'}
      headerActionButton={
        assignedQuestionnaire?.completedAt ? (
          <IconButton
            sx={{ position: 'relative' }}
            disabled={exportIsLoading}
            onClick={async () => {
              setExportIsLoading(true)
              console.log(item, assignedQuestionnaire)
              const res = await dispatch(
                careManagerApi.endpoints.exportTreatmentQuestionnaire.initiate({
                  treatmentId,
                  assignedQuestionnaireId: assignedQuestionnaire.id,
                  patientId,
                }),
              )

              setExportIsLoading(false)
              if (res.error || !res.data) {
                notify({
                  severity: 'error',
                  message:
                    (res.error &&
                      'data' in res.error &&
                      res.error.data?.message) ||
                    'Failed to export Questionnaire',
                })
                return
              }
              downloadContent(
                res.data!,
                `${LABELS.questionnaire}_${assignedQuestionnaire.name}.pdf`,
              )
            }}
          >
            {exportIsLoading && (
              <CircularProgress
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
              />
            )}
            <FileDownload />
          </IconButton>
        ) : undefined
      }
      content={
        isLoading ? (
          <Box minHeight="10rem">
            <CenteredSpinner />
          </Box>
        ) : error ? (
          <Typography color="error">
            Failed to load {LABELS[item.type]}
          </Typography>
        ) : data ? (
          <>
            {assignedQuestionnaire?.status === 'done' && (
              <>
                <Typography
                  gutterBottom
                  display="flex"
                  flexDirection="row"
                  gap={1}
                  alignItems="middle"
                >
                  <CheckCircleOutlined color="success" />
                  <span>
                    {LABELS.score} - {assignedQuestionnaire.score}
                  </span>
                </Typography>
                {assignedQuestionnaire.completedAt && (
                  <Typography color="GrayText" variant="subtitle2" gutterBottom>
                    {`${LABELS.filledOn} ${format(
                      new Date(assignedQuestionnaire.completedAt),
                      dateFormatEnum.FULL_DATE_AND_TIME,
                    )}`}
                  </Typography>
                )}
              </>
            )}
            {data.questions && (
              <Box
                display="grid"
                gridTemplateColumns="min-content auto min-content auto"
                justifyItems="baseline"
                gap={1}
              >
                {data.questions.map(
                  (
                    q: AnsweredQuestionnaireQuestion | QuestionnaireQuestion,
                  ) => {
                    return (
                      <Fragment key={q.id}>
                        {item.type === 'questionnaire' && (
                          <Chip
                            sx={{ gridColumnStart: 1, justifySelf: 'center' }}
                            variant="outlined"
                            color="default"
                            size="small"
                            label={q.number}
                          />
                        )}
                        <Typography sx={{ gridColumnStart: 2 }}>
                          {q.text}
                          {q.isOptional ? (
                            ''
                          ) : (
                            <Typography color="error" component="span">
                              *
                            </Typography>
                          )}
                        </Typography>
                        <QuestionnaireAnswers question={q} />
                      </Fragment>
                    )
                  },
                )}
              </Box>
            )}
          </>
        ) : null
      }
    />
  )
}
