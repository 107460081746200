import React, { useCallback, useMemo } from 'react'
import { type View } from 'react-big-calendar'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import MenuItem from '@mui/material/MenuItem'
import ListSubheader from '@mui/material/ListSubheader'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import { StaticDatePicker } from '@mui/x-date-pickers-pro'
import { Box } from '@mui/system'
import { LinearProgress } from '@mui/material'
import { addMonths, addWeeks, addDays, startOfISOWeek, Locale } from 'date-fns'
import {
  toZonedTime,
  format as defaultFormat,
  fromZonedTime,
} from 'date-fns-tz'
import { enUS } from 'date-fns/locale/en-US'
import { SingleSelect } from '../../base/Select/index'
import { getTimezoneValues } from '../../utils/date'
import { useTranslation } from '../../utils/hooks'
import { useCalendarContext } from './CalendarContext'

interface Props {
  actionButtons?: React.ReactNode
  isLoading?: boolean
  dateLocalization?: { locale: Locale }
}

const defaultLocales: { locale: Locale } = {
  // default to US English
  locale: enUS,
}

export function Topbar({
  actionButtons = null,
  isLoading,
  dateLocalization = defaultLocales,
}: Props) {
  const { selectCalendarState, actions } = useCalendarContext()
  const state = selectCalendarState()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const timezoneOptions = getTimezoneValues()
  const systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const { t } = useTranslation()

  const format = useCallback(
    (date: Date, formatString: string) =>
      defaultFormat(date, formatString, { locale: dateLocalization.locale }),
    [dateLocalization],
  )

  const calendarZonedTime = useMemo(
    () => toZonedTime(state.calenderDate, state.timezone),
    [state.calenderDate, state.timezone],
  )

  const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handleDatePick = (timezone: string) => (date: Date | null) => {
    if (date) {
      actions.changeCalendarDate(fromZonedTime(date, timezone).toISOString())
    }
  }

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Button
          variant="outlined"
          onClick={() =>
            actions.changeCalendarDate(
              toZonedTime(new Date(), state.timezone).toISOString(),
            )
          }
        >
          {t(`today`)}
        </Button>
        <IconButton
          disableRipple
          onClick={() =>
            actions.changeCalendarDate(
              state.view === 'month'
                ? addMonths(new Date(state.calenderDate), -1).toISOString()
                : state.view === 'week'
                ? addWeeks(new Date(state.calenderDate), -1).toISOString()
                : state.view === 'day'
                ? addDays(new Date(state.calenderDate), -1).toISOString()
                : state.calenderDate,
            )
          }
        >
          <ArrowBackIosNewIcon />
        </IconButton>
        <Typography onClick={handlePopoverClick}>
          {state.view === 'month'
            ? format(calendarZonedTime, 'MMM yyyy')
            : state.view === 'week'
            ? `${format(startOfISOWeek(calendarZonedTime), 'MMM yyyy')}`
            : state.view === 'day'
            ? format(calendarZonedTime, 'PP')
            : state.calenderDate}
        </Typography>
        <Popover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          id={anchorEl ? 'simple-popover' : undefined}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
        >
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            openTo="day"
            views={['year', 'month', 'day']}
            minDate={new Date('2000-01-01T00:00:00.000')}
            maxDate={new Date('2040-01-01T00:00:00.000')}
            value={new Date(state.calenderDate)}
            onChange={handleDatePick(state.timezone)}
          />
        </Popover>
        <IconButton
          disableRipple
          onClick={() =>
            actions.changeCalendarDate(
              state.view === 'month'
                ? addMonths(new Date(state.calenderDate), 1).toISOString()
                : state.view === 'week'
                ? addWeeks(new Date(state.calenderDate), 1).toISOString()
                : state.view === 'day'
                ? addDays(new Date(state.calenderDate), 1).toISOString()
                : state.calenderDate,
            )
          }
        >
          <ArrowForwardIosIcon />
        </IconButton>
        <SingleSelect<string>
          variant="outlined"
          formControlProps={{ sx: { m: 1, minWidth: 120 }, size: 'small' }}
          label={t(`Time Zone`)}
          onChange={actions.changeTimezone}
          value={state.timezone}
        >
          <ListSubheader>{t(`System Time Zone`)}</ListSubheader>
          <MenuItem value={systemTimeZone}>
            {t(systemTimeZone.replace('_', ' '))}
          </MenuItem>
          <ListSubheader>{t(`US Time Zones`)}</ListSubheader>
          {timezoneOptions.map((tz) => (
            <MenuItem key={tz.value} value={tz.value}>
              {t(tz.label)}
            </MenuItem>
          ))}
        </SingleSelect>
        <SingleSelect<View>
          variant="outlined"
          formControlProps={{
            sx: { m: 1, minWidth: 100, mr: 'auto !important' },
            size: 'small',
          }}
          label={t(`View`)}
          onChange={actions.changeView}
          value={state.view}
        >
          <MenuItem value="month">{t(`Month`)}</MenuItem>
          <MenuItem value="week">{t(`Week`)}</MenuItem>
          <MenuItem value="day">{t(`Day`)}</MenuItem>
        </SingleSelect>

        {actionButtons}
      </Stack>
      <Box
        sx={{
          height: '1rem',
          alignItems: 'stretch',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        {isLoading && <LinearProgress />}
      </Box>
    </>
  )
}
