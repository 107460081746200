import { PatientProfile } from '@valerahealth/rtk-query'
import {
  parseIntlPhoneNumber,
  setIntlPhoneNumber,
} from '@valerahealth/ui-components'
import {
  dateToDateTimeStrIgnoreTimezone,
  strToDate,
} from '@valerahealth/ui-components/utils/date'

export const formControlProps = {
  sx: { minWidth: '100%', width: '100%' },
  fullWidth: true,
}

export type ProfileObject = Omit<
  PatientProfile,
  'dateOfBirth' | 'onBoardingNotes'
> & {
  patientMessaging: boolean
  FitbitLink: string
  dateOfBirth: Date | null
  onBoardingNotes: string | null
  peripheralDevices?: {
    deviceId: string
    type: 'wirelessScale'
    link: string
  }[]
  useMobileApp?: true
}

export const getProfileObject = (profile: PatientProfile): ProfileObject => {
  const t: ProfileObject = {
    ...profile,
    patientMessaging: !profile.disableChat,
    FitbitLink: 'https://www.fitbit.com/global/us/connect123',
    dateOfBirth: strToDate(profile.dateOfBirth),
    onBoardingNotes: profile.onBoardingNotes || null,
    phone: parseIntlPhoneNumber(profile.phone),
    primaryPhone: parseIntlPhoneNumber(profile.primaryPhone || ''),
  }

  if (t.scaleId) {
    t.peripheralDevices = [
      {
        deviceId: t.scaleId,
        type: 'wirelessScale',
        link: 'https://www.fitbit.com/global/us/connect123',
      },
    ]
  }

  if (t.device?.useMobileApp) {
    t.useMobileApp = true
  }

  return t
}

export const getTreatmentServerObject = (
  patient: any,
  type?: any,
  supportiveContact?: any,
) => {
  const dateOfBirth = dateToDateTimeStrIgnoreTimezone(
    typeof patient.dateOfBirth === 'string'
      ? strToDate(patient.dateOfBirth)
      : patient.dateOfBirth,
  )
  const getQuestionnaires = () => {
    const arr = []
    if (typeof patient.phqScore === 'number' && patient.phqScore > -1) {
      arr.push({
        name: 'PHQ',
        score: patient.phqScore,
      })
    }
    if (typeof patient.gad7Score === 'number' && patient.gad7Score > -1) {
      arr.push({
        name: 'GAD7',
        score: patient.gad7Score,
      })
    }
    return arr.length ? arr : undefined
  }
  let data
  if (!patient.pending && patient.id) {
    data = {
      profileId: patient.profileId,
      firstName: patient.firstName.trim(),
      lastName: patient.lastName.trim(),
      address: patient.address || null,
      addressComponents:
        {
          city: patient.addressComponents.city || undefined,
          state: patient.addressComponents.state || undefined,
          street: patient.addressComponents.street || undefined,
          zipcode: patient.addressComponents.zipcode || undefined,
        } || null,
      gender: patient.gender,
      preferredName: patient.preferredName?.trim() || null,
      username: patient.username?.trim() || undefined,
      middleName: patient.middleName?.trim() || null,
      pronouns: patient.pronouns || null,
      genderIdentity: patient.genderIdentity,
      genderDetails: patient.genderDetails || null,
      phone: setIntlPhoneNumber(patient.phone.trim()),
      email: patient.email?.trim() || null,
      primaryPhone: patient.primaryPhone
        ? setIntlPhoneNumber(patient.primaryPhone.trim())
        : undefined,
      primaryEmail: patient.primaryEmail?.trim() || undefined,
      dateOfBirth,
      status: patient.status,
      mrn: patient.mrn?.trim() || undefined,
      diagnosis: patient.diagnosis,
      languages: patient.languages,
      forceMultiPhone: patient.forceMultiPhone,
      preferredLanguage: patient.preferredLanguage,
      timezone: patient.timezone,
      scaleId: patient.scaleId || null,
      questionnaires: getQuestionnaires(),
      disableChat: !patient.patientMessaging,
      device: {
        type,
        useMobileApp: patient.useMobileApp,
      },
    }
  } else {
    data = {
      profile: {
        firstName: patient.firstName.trim(),
        lastName: patient.lastName.trim(),
        address: patient.address || null,
        gender: patient.gender,
        genderIdentity: patient.genderIdentity,
        preferredName: patient.preferredName?.trim() || null,
        pronouns: patient.pronouns,
        timezone: patient.timezone,
        // phone: setIntlPhoneNumber(patient.phone.trim()),
        email: patient.email?.trim() || undefined,
        dateOfBirth,
      },
      treatment: {
        mrn: patient.mrn?.trim() || undefined,
        diagnosis: patient.diagnosis,
        scaleId:
          (patient.peripheralDevice === 'wirelessScale' && patient.scaleId) ||
          undefined,
        questionnaires: getQuestionnaires(),
        disableChat: !patient.patientMessaging,
        device: {
          type,
          useMobileApp: !patient.patientWithoutApp,
        },
      },
      careTeam: {
        pctmId: patient.pctm,
        members: patient.careTeam.map((cm: any) => {
          return { ctmId: cm }
        }),
      },
      planTemplateIds: patient.plans,
      supportiveContact: supportiveContact.firstName
        ? supportiveContact
        : undefined,
    }
  }
  return data
}
