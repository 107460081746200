import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  NavLink,
  Navigate,
  Outlet,
  useParams,
  useNavigate,
} from 'react-router-dom'
import './style.less'
import {
  IconButton,
  MenuItem,
  PopupMenu,
  Tooltip,
  CenteredSpinner,
  Typography,
  useMediaQuery,
  useNotify,
  useTheme,
  Stack,
  Box,
  Button,
} from '@valerahealth/ui-components'
import { useTranslation } from '@valerahealth/ui-translation'
import {
  AppointmentFragment,
  CMAppointmentRef,
  careManagerApi,
  patientRegistrationApi,
  schedulingApi,
} from '@valerahealth/rtk-query'
import {
  MoreVert,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@valerahealth/ui-components/icons'
import { type RootState, useReduxDispatch, useReduxSelector } from 'redux/store'
import {
  ApptSummry,
  type TreatmentRoomRouteContext,
} from 'components/utilities/treatments'
import {
  configProgramSettingsSelector,
  isWellnessSelector,
} from 'redux/selectors'
import {
  selectTreatment,
  unselectTreatment,
} from '../../redux/actions/treatmentRoom'
import { toggleSidebar } from '../../redux/actions/globalActions'
import TreatmentHeader from './TreatmentHeader'
import TreatmentSidebar from '../TreatmentSidebar'

const { useGetNextLastApptByTreatmentIdQuery } = schedulingApi

const toApptSummary = (
  appt?:
    | Pick<AppointmentFragment, '_id' | 'startDate' | 'endDate'>
    | CMAppointmentRef
    | null,
): ApptSummry | undefined => {
  if (!appt) return undefined
  if ('date' in appt) {
    return {
      isLegacyAppt: true,
      id: appt.id,
      start: new Date(appt.date),
    }
  }
  return {
    isLegacyAppt: false,
    id: appt._id,
    start: new Date(appt.startDate),
    end: new Date(appt.endDate),
  }
}

const now = new Date().toISOString()
export const SIDEBAR_WIDTH = 33
const PROJECT_DISPLAY_NEW_CARE_TEAM_TAB =
  process.env.PROJECT_DISPLAY_NEW_CARE_TEAM_TAB === 'true'

const RightHeaderMenu = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <PopupMenu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      button={
        <IconButton
          size="small"
          aria-label="valera apps"
          aria-controls="menu-appbar"
          aria-haspopup="true"
        >
          <MoreVert />
        </IconButton>
      }
    >
      <MenuItem
        onClick={() => {
          navigate('plan')
        }}
      >
        {t('tracks')}
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate('data')
        }}
      >
        {t('data')}
      </MenuItem>
    </PopupMenu>
  )
}

export default function TreatmentRoom() {
  const { t } = useTranslation()
  const dispatch = useReduxDispatch()
  const notify = useNotify()
  const theme = useTheme()
  const fullWidth = useMediaQuery(theme.breakpoints.up(1400))
  const treatmentId = useParams<{ treatmentId: string }>().treatmentId!
  const isWellness = useSelector(isWellnessSelector)
  const configProgramSettings = useReduxSelector(configProgramSettingsSelector)
  const useSchedulingAppt =
    configProgramSettings?.appointmentSettings?.flags?.useSchedulingAppt
  const { goToCaseload } = useSelector((state: RootState) => state.caseload)
  const { confirmRedirection, openSidebar } = useSelector(
    (state: RootState) => state.global,
  )
  const displayNote = useSelector((state: RootState) => {
    try {
      return !!(
        state.program.configurations?.clinicalNotes?.active &&
        state.global?.user?.permissions?.accessToNotes
      )
    } catch (error) {
      return false
    }
  })

  useEffect(() => {
    dispatch(selectTreatment(treatmentId))
    return () => {
      dispatch(unselectTreatment())
    }
  }, [dispatch, treatmentId])

  const {
    nextAppt,
    lastAppt,
    error: getApptError,
  } = useGetNextLastApptByTreatmentIdQuery(
    {
      id: treatmentId,
      now,
    },
    {
      skip: !useSchedulingAppt,
      selectFromResult: ({ data, isLoading, isUninitialized, error }) => ({
        nextAppt: data?.getNextApptByPatientId?.results?.length
          ? data?.getNextApptByPatientId?.results[0]
          : undefined,
        lastAppt: data?.getLastApptByPatientId?.results?.length
          ? data?.getLastApptByPatientId?.results[0]
          : undefined,
        isLoading: isLoading || isUninitialized,
        error,
      }),
    },
  )

  const {
    data: treatment,
    isLoading: isTreatmentLoading,
    error: getTreatmentError,
  } = careManagerApi.useGetTreatmentQuery(
    { treatmentId },
    {
      selectFromResult: ({ data, isLoading, isUninitialized, error }) => ({
        data,
        isLoading: isLoading || isUninitialized,
        error,
      }),
    },
  )
  const {
    data: treatmentProfile,
    isLoading: isTreatmentProfileLoading,
    error: getTreatmentProfileError,
  } = careManagerApi.useGetPatientProfileByTreatmentIdQuery(
    { id: treatmentId },
    {
      selectFromResult: ({ data, isLoading, isUninitialized, error }) => ({
        data,
        isLoading: isLoading || isUninitialized,
        error,
      }),
    },
  )
  const { data: emrStatus, error: getEMRStatusError } =
    patientRegistrationApi.useGetPatientByTreatmentIdQuery(
      { treatmentId },
      {
        skip: !isWellness,
        selectFromResult: ({ data, error }) => ({
          data: data?.getPatientByTreatmentId.integration ?? undefined,
          error,
        }),
      },
    )
  const isPageLoading = isTreatmentLoading || isTreatmentProfileLoading
  const isPageError = getTreatmentError || getTreatmentProfileError

  useEffect(() => {
    if (getTreatmentError) {
      const message =
        'data' in getTreatmentError ? getTreatmentError.data?.message : ''
      notify({
        message: message || 'Could not load Patient Treatment Record',
        severity: 'error',
      })
      console.error(getTreatmentError)
    }
    if (getEMRStatusError) {
      const message =
        'data' in getEMRStatusError ? getEMRStatusError.message : ''
      notify({
        message: message || 'Could not load Patient EMR Status',
        severity: 'error',
      })
      console.error(getEMRStatusError)
    }
    if (getTreatmentProfileError) {
      const message =
        'data' in getTreatmentProfileError
          ? getTreatmentProfileError.data?.message
          : ''
      notify({
        message: message || 'Could not load Patient Profile Record',
        severity: 'error',
      })
      console.error(getTreatmentProfileError)
    }
    if (getApptError) {
      notify({
        message:
          getApptError.message ||
          'An error occurred while retrieving the previous and next appointments for the Patient',
        severity: 'error',
      })
      console.error(getApptError)
    }
  }, [
    getTreatmentError,
    getEMRStatusError,
    getTreatmentProfileError,
    getApptError,
    notify,
  ])

  // only used inside a conditional that
  const routeContext: TreatmentRoomRouteContext = {
    treatment: treatment!,
    treatmentProfile: treatmentProfile!,
    treatmentEmrStatus: emrStatus,
    nextAppt: toApptSummary(
      useSchedulingAppt ? nextAppt : treatment?.nextAppointment,
    ),
    lastAppt: toApptSummary(
      useSchedulingAppt ? lastAppt : treatment?.lastAppointment,
    ),
  }

  return (
    <>
      {goToCaseload && <Navigate to="/caseload" replace />}
      <TreatmentHeader {...routeContext} />
      <Stack
        flexDirection="row"
        flexGrow={1}
        maxHeight="100%"
        sx={{
          overflowX: 'auto',
          overflowY: 'hidden',
        }}
      >
        <Box flex="2 2 100%">
          <Box
            component="nav"
            sx={{
              height: '4rem',
              display: 'flex',
              gap: 2,
              wrap: 'no-wrap',
              alignItems: 'center',
              p: '0 2rem 0 1rem',
              a: {
                color: (theme) => theme.palette.action.disabled,
              },
              'a.selected-sub-menu': {
                fontWeight: 'bold',
                color: (theme) => theme.palette.action.active,
              },
            }}
          >
            <NavLink
              to="timeline"
              className={({ isActive }) =>
                isActive ? 'selected-sub-menu' : ''
              }
            >
              {t('Timeline')}
            </NavLink>
            <NavLink
              to="basic-info"
              className={({ isActive }) =>
                isActive ? 'selected-sub-menu' : ''
              }
            >
              {t('basicInfo')}
            </NavLink>
            {isWellness && (
              <NavLink
                to="billing"
                className={({ isActive }) =>
                  isActive ? 'selected-sub-menu' : ''
                }
              >
                {t('billing')}
              </NavLink>
            )}
            <NavLink
              to="alerts"
              className={({ isActive }) =>
                isActive ? 'selected-sub-menu' : ''
              }
            >
              {t('Alerts')}
            </NavLink>

            <Tooltip
              title={
                isWellness && !emrStatus?.emrId
                  ? t('patientNotInDrChronoMessage')
                  : ''
              }
            >
              {/** tooltip messes with detection of is active due to component cloning, div wrap fixes */}
              <div>
                <NavLink
                  to="appointments"
                  onClick={(e) => {
                    if (isWellness && !emrStatus?.emrId) {
                      e.preventDefault()
                      return false
                    }
                  }}
                  className={({ isActive }) => {
                    return isActive
                      ? 'selected-sub-menu'
                      : isWellness && !emrStatus?.emrId
                      ? 'disabled-sub-menu'
                      : ''
                  }}
                >
                  {t('appointments')}
                </NavLink>
              </div>
            </Tooltip>

            {displayNote && (
              <NavLink
                to="note"
                className={({ isActive }) =>
                  isActive ? 'selected-sub-menu' : ''
                }
              >
                {t('notes')}
              </NavLink>
            )}
            <NavLink
              to="careteam"
              className={({ isActive }) =>
                isActive ? 'selected-sub-menu' : ''
              }
            >
              {t('CareTeam')}
            </NavLink>
            {PROJECT_DISPLAY_NEW_CARE_TEAM_TAB && (
              <NavLink
                to="careteam2"
                className={({ isActive }) =>
                  isActive ? 'selected-sub-menu' : ''
                }
              >
                CareTeam DRAFT
              </NavLink>
            )}
            <NavLink
              to="documents"
              className={({ isActive }) =>
                isActive ? 'selected-sub-menu' : ''
              }
            >
              {t('documents')}
            </NavLink>
            {fullWidth ? (
              <>
                <NavLink
                  to="plan"
                  className={({ isActive }) =>
                    isActive ? 'selected-sub-menu' : ''
                  }
                >
                  {t('tracks')}
                </NavLink>
                <NavLink
                  to="data"
                  className={({ isActive }) =>
                    isActive ? 'selected-sub-menu' : ''
                  }
                >
                  {t('data')}
                </NavLink>
              </>
            ) : (
              (treatment && !confirmRedirection && <RightHeaderMenu />) || ''
            )}
          </Box>
          <div className="treatment-room-container">
            {isPageLoading ? (
              <CenteredSpinner />
            ) : isPageError ? (
              <Typography>An error occured</Typography>
            ) : (
              <Outlet context={routeContext} />
            )}
          </div>
        </Box>

        <Box
          position="relative"
          flex="1 1 auto"
          display="flex"
          flexDirection="column"
          sx={
            openSidebar
              ? {
                  minWidth: '20rem',
                  maxWidth: '30rem',
                  flexBasis: '100%',
                }
              : undefined
          }
        >
          <Button
            size="small"
            color="secondary"
            sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              transform: 'translate(-100%,-100%) rotate(-90deg)',
              transformOrigin: 'bottom right',
              zIndex: 2,
              borderRadius: '5px 5px 0px 0px',
              whiteSpace: 'nowrap',
              lineHeight: 1.5,
              fontSize: '.675rem',
              textTransform: 'none',
            }}
            onClick={() => {
              dispatch(toggleSidebar())
            }}
            endIcon={openSidebar ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          >
            {openSidebar ? t('hideSidebar') : t('expandSidebar')}
          </Button>
          {openSidebar && <TreatmentSidebar />}
        </Box>
      </Stack>
    </>
  )
}
