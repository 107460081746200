import React from 'react'
import { Popup } from '@valerahealth/ui-core'
import { useTranslation } from '@valerahealth/ui-translation'
import { useSelector } from 'react-redux'

const PhoneAlreadyExist = ({ handleClose, sameClinic, handleContinue }) => {
  const { t } = useTranslation()
  const programName = useSelector(
    (state) => state.program?.configurations?.name,
  )

  return (
    <Popup
      title={t('registerWithExistingPhoneNumber')}
      onClosed={() => {
        handleClose()
      }}
      buttons={[
        {
          text: t('back'),
          type: 'link',
          onClick: () => {
            handleClose()
          },
        },
        {
          text: t('addToClinic'),
          type: 'primary',
          onClick: () => {
            handleClose()
            handleContinue()
          },
        },
      ]}
    >
      <div>
        <div>
          {sameClinic
            ? t('phoneAlreadyExist2').replace('{TenantName}', programName)
            : t('phoneAlreadyExist1')}
        </div>
        <div>
          {sameClinic
            ? t('phoneAlreadyExist3')
            : t('phoneAlreadyExist3') + `: ${programName}`}
          ?
        </div>
      </div>
    </Popup>
  )
}

export default PhoneAlreadyExist
