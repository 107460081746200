import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  Grid,
  Box,
  Stack,
  Typography,
  Button,
  useNotify,
  IconButton,
  EditIcon,
  Tooltip,
} from '@valerahealth/ui-components'
import {
  dateToISODateStr,
  format,
  formatIsoDate,
  strToDate,
  addDays,
} from '@valerahealth/ui-components/utils/date'
import {
  TaskServiceType,
  TaskStatus,
  TaskType,
  taskApi,
  ParsedTaskFragment,
  TaskJsonVersion,
} from '@valerahealth/rtk-query'
import { Mode } from 'components/TaskBar/constants'
import {
  FormProvider,
  useForm,
  SaveButton,
  TextField,
  Combobox,
  DatePicker,
  onlyDirtyFields,
  useFormContext,
  ReferralSourceSelect,
} from '@valerahealth/ui-components/form'
import { useTranslation } from '@valerahealth/ui-translation'
import { InfoOutlined } from '@valerahealth/ui-components/icons'
import {
  PatientTypeAhead,
  usePatientVerification,
} from '@valerahealth/ui-components/features'
import { isWellnessSelector } from 'redux/selectors'
import {
  TaskStatusSelectRead,
  TaskStatusSelectWrite,
  TypographyTemplate,
  CommentTypography,
  DeleteButton,
  AssignedCombobox,
  PatientPreferencePanel,
  valueStyle,
} from './Fields'
import {
  ColumnKey,
  PatientTaskField,
  SubmittedTaskFormType,
  TaskFormType,
} from './types'

// initialized the form, accepts undefined (during create) and a task (during edit)
const taskToForm = (
  task?: Partial<ParsedTaskFragment> | null,
  patient: PatientTaskField | null = null,
  typeDefaultValue?: TaskType,
): TaskFormType => {
  const {
    type = typeDefaultValue || null,
    status = TaskStatus.ToDo,
    description = '',
    dueDate,
    referralSource = null,
    serviceType = null,
    assignee = null,
    patientPreferences,
  } = task || {}
  return {
    type,
    status,
    description,
    dueDate: dueDate ? strToDate(dueDate) : addDays(new Date(), 1),
    referralSource,
    serviceType,
    patient,
    assignee,
    comment: '',
    patientPreferences: patientPreferences?.value || {},
  }
}

const PatientField = () => {
  const { t } = useTranslation()
  const { watch } = useFormContext<TaskFormType>()
  const patient = watch(ColumnKey.patient)
  const { mrn, treatmentId } = patient || {}
  const { isBillingLoading, message, isWarning } =
    usePatientVerification(treatmentId)

  return (
    <>
      <Grid item xs={6}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <PatientTypeAhead
            size="small"
            sx={{
              '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: (theme) =>
                  isWarning ? theme.palette.warning.main : '',
              },
            }}
            textFieldProps={{
              color: isWarning ? 'warning' : undefined,
            }}
            label={t('Patient')}
            name={ColumnKey.patient}
            required
            setValueAs={(option): PatientTaskField | null => {
              if (!option) return null
              return {
                patientId: option.id,
                treatmentId: option.treatmentId,
                display: option.name.fullName,
                mrn: option.mrn,
              }
            }}
            parseValue={(value: PatientTaskField | null, options) => {
              return (
                (value &&
                  options.find((o) => o.treatmentId === value.treatmentId)) ||
                null
              )
            }}
          />
          <Tooltip title={t('patientTypeAheadInfo')}>
            <InfoOutlined sx={{ ml: '2px', mr: '-6px' }} />
          </Tooltip>
        </Box>
        {isBillingLoading ? (
          <Typography
            sx={{
              fontSize: (theme) => theme.typography.caption,
              ml: '2px',
              lineHeight: '1.2',
            }}
          >
            {t('Verifying patient Status...')}
          </Typography>
        ) : isWarning ? (
          <Typography
            sx={{
              color: (theme) => theme.palette.warning.main,
              fontSize: (theme) => theme.typography.caption,
              ml: '2px',
              lineHeight: '1.2',
            }}
          >
            {message}
          </Typography>
        ) : null}
      </Grid>
      <Grid item xs={6}>
        <Typography sx={{ ml: 1, mt: 1 }}>{`MRN: ${mrn || ''}`}</Typography>
      </Grid>
    </>
  )
}

const PatientFieldReadOnlyWriteForm = ({
  patient,
}: {
  patient: PatientTaskField | undefined
}) => {
  const { t } = useTranslation()
  const { message, isWarning, isBillingLoading } = usePatientVerification(
    patient?.treatmentId,
  )
  return (
    <>
      <Grid item xs={6}>
        <Typography sx={{ ml: 0.5 }}>{`Patient: ${
          patient?.display || ''
        }`}</Typography>
        {isBillingLoading ? (
          <Typography
            sx={{
              fontSize: (theme) => theme.typography.caption,
              ml: '2px',
              lineHeight: '1.2',
              mb: '-2px',
            }}
          >
            {t('Verifying patient Status...')}
          </Typography>
        ) : isWarning ? (
          <Typography
            sx={{
              color: (theme) => theme.palette.warning.main,
              fontSize: (theme) => theme.typography.caption,
              ml: '2px',
              lineHeight: '1.2',
              mb: '-2px',
            }}
          >
            {message}
          </Typography>
        ) : null}
      </Grid>
      <Grid item xs={6}>
        <Typography sx={{ ml: 1, mb: 0.5 }}>{`MRN: ${
          patient?.mrn || ''
        }`}</Typography>
      </Grid>
    </>
  )
}

const PatientFieldReadForm = ({
  patient,
}: {
  patient: PatientTaskField | undefined
}) => {
  const { t } = useTranslation()
  const { message, isWarning, isBillingLoading } = usePatientVerification(
    patient?.treatmentId,
  )
  return (
    <>
      <Grid item xs={6}>
        <Typography
          sx={{ fontSize: (theme) => theme.typography.caption }}
        >{`Patient: ${patient?.display || ''}`}</Typography>
        {isBillingLoading ? (
          <Typography
            sx={{
              fontSize: (theme) => theme.typography.caption,
              ml: '2px',
              lineHeight: '1.2',
              mb: '-2px',
            }}
          >
            {t('Verifying patient Status...')}
          </Typography>
        ) : isWarning ? (
          <Typography
            sx={{
              color: (theme) => theme.palette.warning.main,
              fontSize: (theme) => theme.typography.caption,
              ml: '2px',
              lineHeight: '1.2',
              mb: '-2px',
            }}
          >
            {message}
          </Typography>
        ) : null}
      </Grid>
      <Grid item xs={6}>
        <Typography
          sx={{ fontSize: (theme) => theme.typography.caption }}
        >{`MRN: ${patient?.mrn || ''}`}</Typography>
      </Grid>
    </>
  )
}

export const ServiceTypeWriteCombobox = ({
  required,
}: {
  required?: boolean
}) => {
  const { t } = useTranslation()
  return (
    <Combobox
      label={t('Service Type')}
      name={ColumnKey.serviceType}
      fullWidth
      options={Object.values(TaskServiceType).sort()}
      getOptionLabel={(v) => t(`task.taskServiceType.${v}`)}
      size="small"
      required={required}
    />
  )
}

export const DueDatePicker = ({
  required,
}: {
  required?: string | boolean
}) => {
  const { t } = useTranslation()
  return (
    <DatePicker
      fullWidth
      label={t('Due')}
      name={ColumnKey.dueDate}
      textFieldProps={{
        fullWidth: true,
        size: 'small',
      }}
      required={required}
    />
  )
}

export const CommentWriteTextField = () => {
  const { t } = useTranslation()
  return (
    <TextField
      fullWidth
      label={t('Comment')}
      name={ColumnKey.comment}
      multiline
      validate={(value) => value === '' || (value as string)?.trim() !== ''}
    />
  )
}

const gridSpacing = 1.5
const enablePatientPreferenceSet = new Set([
  TaskType.CaseTransfer,
  TaskType.PatientTransfer,
  TaskType.NewPatientCallCenter,
  TaskType.NewPatientReferral,
  TaskType.NewPatientSelf,
  TaskType.NewPatientWaitlist,
  TaskType.NewPatientToc,
])

const makeReferralRequiredSet = new Set([
  TaskType.NewPatientReferral,
  TaskType.NewPatientCallCenter,
  TaskType.NewPatientCallReferral,
  TaskType.NewPatientSelf,
  TaskType.NewPatientToc,
])

const makeServiceTypeRequiredSet = new Set([
  TaskType.CaseTransfer,
  TaskType.PatientTransfer,
])

export type ReadWriteTaskFormProps = {
  mode?: Mode
  patient?: PatientTaskField
  onCancel: () => void
  onEdit?: () => void
  task?: ParsedTaskFragment | null
  onSaveSuccess?: (task: ParsedTaskFragment) => void
  onDeleteSuccess?: (task: ParsedTaskFragment) => void
  patientReadOnly?: boolean
}

export function ReadWriteTaskForm({
  mode = Mode.READ,
  task: _task,
  patient,
  patientReadOnly = false,
  onCancel,
  onEdit,
  onSaveSuccess,
  onDeleteSuccess,
}: ReadWriteTaskFormProps) {
  const { t } = useTranslation()
  const notify = useNotify()
  const isWellness = useSelector(isWellnessSelector)
  const [createTaskMutation, createRes] = taskApi.useCreateTaskMutation()
  const [updateTaskMutation, updateRes] = taskApi.useUpdateTaskMutation()
  const task =
    (updateRes.data
      ? updateRes.data._id === _task?._id
        ? updateRes.data
        : _task
      : _task) || createRes.data
  const showPatientSelect = !patient && !task && !createRes.data?.patientId

  const methods = useForm<TaskFormType>({
    defaultValues: taskToForm(
      task,
      patient,
      isWellness ? undefined : TaskType.Other,
    ),
  })

  const formTaskType = methods.watch(ColumnKey.type)

  const currentTaskType = methods.watch('type')
  const showPatientPreferencePanel =
    !!currentTaskType && enablePatientPreferenceSet.has(currentTaskType)
  const isReferralRequired =
    !!currentTaskType && makeReferralRequiredSet.has(currentTaskType)
  const FieldNode = useCallback(
    ({
      view = null,
      edit = null,
    }: {
      view?: JSX.Element | null
      edit?: JSX.Element | null
    }) => (mode === Mode.READ ? view : edit),
    [mode],
  )

  const onFormSubmit = async (fields: SubmittedTaskFormType) => {
    const {
      patient: _patient,
      assignee: { _id },
      dueDate: due,
      patientPreferences,
      ...rest
    } = fields

    const { patientId, treatmentId, ...patient } = _patient || {}

    const dueDate = dateToISODateStr(due)!

    const { dirtyFields } = methods.formState

    const res = task
      ? await updateTaskMutation({
          content: {
            ...onlyDirtyFields(dirtyFields, {
              ...rest,
              dueDate,
              assignee: _id!,
            }),
            patientPreferences: enablePatientPreferenceSet.has(rest.type)
              ? {
                  type: TaskJsonVersion.PatientPreferences_V1,
                  value: patientPreferences,
                }
              : null,
            id: task._id,
          },
        })
      : await createTaskMutation({
          content: {
            patientId: patientId!,
            patient,
            treatmentId: treatmentId!,
            assignee: _id!,
            dueDate,
            patientPreferences: enablePatientPreferenceSet.has(rest.type)
              ? {
                  type: TaskJsonVersion.PatientPreferences_V1,
                  value: patientPreferences,
                }
              : undefined,
            ...rest,
          },
        })
    if ('error' in res) {
      notify({
        message: res.error.message || t('networkError.500'),
        severity: 'error',
      })
      return
    }
    methods.reset({
      ...fields,
      comment: '',
    })
    notify({ message: t('task.success_task_save'), severity: 'success' })
    onSaveSuccess?.(res.data)
  }
  const handleCancel = () => {
    onCancel()
    methods.reset(taskToForm(task || patient))
  }

  const currentSelectTreatmentId =
    patient?.treatmentId || methods.watch(ColumnKey.patient)?.treatmentId

  return (
    <Grid
      container
      spacing={gridSpacing}
      component="form"
      onSubmit={methods.handleSubmit((values) =>
        // given the validation rules we know that the submitted data will be more strict than the forms initial state
        onFormSubmit(values as SubmittedTaskFormType),
      )}
      sx={{ pt: 1 }}
    >
      <FormProvider {...methods}>
        {isWellness && (
          <Grid item xs={6}>
            <FieldNode
              view={
                <TypographyTemplate
                  item={t('Type')}
                  value={task?.type ? t(`task.taskType.${task.type}`) : ''}
                />
              }
              edit={
                <Combobox
                  label={t('Type')}
                  name={ColumnKey.type}
                  fullWidth
                  options={Object.values(TaskType).sort((a, b) =>
                    t(`task.taskType.${a}`).localeCompare(
                      t(`task.taskType.${b}`),
                    ),
                  )}
                  getOptionLabel={(v) => t(`task.taskType.${v}`)}
                  size="small"
                  required
                />
              }
            />
          </Grid>
        )}
        <Grid item xs={isWellness ? 6 : 12}>
          <FieldNode
            view={
              <Stack direction="row" alignItems="center">
                <Box sx={{ mr: 'auto' }}>
                  {task && <TaskStatusSelectRead task={task} />}
                </Box>
                {onEdit && (
                  <IconButton title="Edit" onClick={onEdit} size="small">
                    <EditIcon />
                  </IconButton>
                )}
                {task && (
                  <DeleteButton
                    task={task}
                    onDeleteSuccess={onDeleteSuccess}
                    size="small"
                  />
                )}
              </Stack>
            }
            edit={
              <Box
                display="flex"
                sx={{ ml: '8px', height: '100%', alignItems: 'center' }}
              >
                <TaskStatusSelectWrite task={task || null} />
              </Box>
            }
          />
        </Grid>
        {showPatientSelect ? (
          <FieldNode
            edit={<PatientField />}
            view={<PatientFieldReadForm patient={patient} />}
          />
        ) : (
          patientReadOnly && (
            <FieldNode
              edit={<PatientFieldReadOnlyWriteForm patient={patient} />}
              view={<PatientFieldReadForm patient={patient} />}
            />
          )
        )}

        {isWellness && (
          <>
            <Grid item xs={6}>
              <FieldNode
                view={
                  <TypographyTemplate
                    item={t('Service Type')}
                    value={
                      task?.serviceType
                        ? t(`task.taskServiceType.${task?.serviceType}`)
                        : ''
                    }
                  />
                }
                edit={
                  <ServiceTypeWriteCombobox
                    required={makeServiceTypeRequiredSet.has(formTaskType!)}
                  />
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FieldNode
                view={
                  <TypographyTemplate
                    item={t('Referral Source')}
                    value={task?.referralSource || ''}
                  />
                }
                edit={
                  <ReferralSourceSelect
                    label={t('Referral Source')}
                    name={ColumnKey.referralSource}
                    fullWidth
                    size="small"
                    required={isReferralRequired}
                  />
                }
              />
            </Grid>
          </>
        )}
        {showPatientPreferencePanel && (
          <Grid item xs={12}>
            <FieldNode
              view={<PatientPreferencePanel mode={Mode.READ} />}
              edit={
                <PatientPreferencePanel
                  mode={Mode.WRITE}
                  isNew={!task}
                  currentSelectTreatmentId={currentSelectTreatmentId}
                />
              }
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FieldNode
            view={
              <TypographyTemplate
                item={t('Task')}
                value={task?.description || ''}
                props={{
                  valueProps: {
                    sx: {
                      ...valueStyle.sx,
                      whiteSpace: 'pre',
                      maxHeight: '300px',
                      overflow: 'auto',
                      display: 'block',
                    },
                  },
                }}
              />
            }
            edit={
              <TextField
                fullWidth
                label={t('Task')}
                name={ColumnKey.description}
                multiline
                required
                validate={(value) => (value as string)?.trim() !== ''}
              />
            }
          />
        </Grid>
        <FieldNode
          view={
            task && (
              <>
                <Grid item xs={6}>
                  <TypographyTemplate
                    item={t('Reported')}
                    value={task?.createdBy.display || ''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TypographyTemplate
                    item={t('Added')}
                    value={task ? format(new Date(task.createdDate), 'P') : ''}
                  />
                </Grid>
              </>
            )
          }
        />
        <Grid item xs={6}>
          <FieldNode
            view={
              <TypographyTemplate
                item={t('Assigned')}
                value={task?.assignee?.display || ''}
              />
            }
            edit={<AssignedCombobox required />}
          />
        </Grid>
        <Grid item xs={6}>
          <FieldNode
            view={
              <TypographyTemplate
                item={t('Due')}
                value={task ? formatIsoDate(task.dueDate, 'P') : ''}
              />
            }
            edit={
              <DueDatePicker
                required={t('form_required_field', {
                  field: t('Due'),
                })}
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FieldNode
            view={<CommentTypography comments={task?.comments} />}
            edit={<CommentWriteTextField />}
          />
        </Grid>
        {mode === Mode.WRITE && (
          <Grid item xs={12}>
            <Stack direction="row" gap={gridSpacing}>
              <Button
                variant="outlined"
                color="error"
                sx={{ ml: 'auto' }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <SaveButton
                isError={createRes.isError || updateRes.isError}
                isSuccess={createRes.isSuccess || updateRes.isSuccess}
              />
            </Stack>
          </Grid>
        )}
      </FormProvider>
    </Grid>
  )
}
