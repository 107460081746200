import { createApi } from '@reduxjs/toolkit/query/react'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import { AUTH_REDUCER_KEY, RootStateWithAuth } from '@valerahealth/redux-auth'
import { ErrorResponse } from '@rtk-query/graphql-request-base-query/dist/GraphqlBaseQueryTypes'
import { type Overwrite } from 'utility-types'
import * as Types from '../../shared/generated.types'
import {
  AggregateName,
  AggregationsAggregate,
  QueryDslBoolQuery,
  AggregationsAggregationContainer,
  SearchSortContainer,
} from '../../opensearch.types'
import {
  CreateTaskDocument,
  DeleteTaskDocument,
  GetSearchTasksAggregatesDocument,
  GetTaskDocument,
  GetTasksByTreatmentIdDocument,
  SearchTasksDocument,
  UpdateTaskDocument,
  UpdateTasksDocument,
  api as endpoints,
} from './generated'
import { SearchTasksQueryType, getQueryType } from './taskESQuery'
import {
  ParsedTaskFragment,
  parseTaskFragment,
  TaskPatientPreferences,
  taskToSearchableTask,
} from './taskUtils'

export const taskApi = createApi({
  reducerPath: 'taskApi',
  tagTypes: ['task', 'tasksByTreatment', 'tasksByUser'],
  keepUnusedDataFor: 120,
  baseQuery: graphqlRequestBaseQuery({
    url: `https://${process.env.TASK_API_DOMAIN}/graphql`,
    customErrors: (error) => {
      console.error(error.response)
      const e = error.response.errors?.[0] as any
      const response: ErrorResponse = {
        name: e?.errorType || '',
        message: e?.message || '',
        stack: error.stack || '',
      }
      return response
    },
    prepareHeaders: (headers, api) => {
      const state = api.getState() as RootStateWithAuth
      const { isAuthenticated } = state[AUTH_REDUCER_KEY]
      if (isAuthenticated) {
        const accessToken = (state as RootStateWithAuth)[AUTH_REDUCER_KEY]
          .session?.accessToken.jwt
        headers.set('Authorization', `Bearer ${accessToken}`)
      }
      return headers
    },
  }),
  endpoints,
}).injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getEnhancedSearchTaskAggregates: build.query<
      Record<AggregateName, AggregationsAggregate>,
      {
        query: QueryDslBoolQuery
        aggregations: Record<AggregateName, AggregationsAggregationContainer>
      }
    >({
      query: (i) => ({
        document: GetSearchTasksAggregatesDocument,
        variables: {
          aggregations: JSON.stringify(i.aggregations),
          query: JSON.stringify(i.query),
        },
      }),
      transformResponse: (
        result: Types.GetSearchTasksAggregates,
      ): Record<AggregateName, AggregationsAggregate> => {
        const agg = result.searchTasks.aggregations
        let response: Record<AggregateName, AggregationsAggregate> = {}
        try {
          if (agg) response = JSON.parse(agg) as typeof response
        } catch (e) {
          console.error(e)
        }
        return response
      },
    }),
    searchTasks: build.query<
      Types.SearchTasks,
      Overwrite<
        Types.SearchTasksVariables,
        {
          query: QueryDslBoolQuery
          sort?: SearchSortContainer[]
        }
      >
    >({
      query: ({ query, sort, ...rest }) => ({
        document: SearchTasksDocument,
        variables: {
          query: JSON.stringify(query),
          sort: sort && JSON.stringify(sort),
          ...rest,
        },
      }),
      providesTags: (res, err, { query }) => {
        const queryType = getQueryType(query)
        const tasks = res?.searchTasks.tasks || []
        if (queryType === SearchTasksQueryType.assigneeTodo) {
          return [
            ...tasks.map(({ id }) => ({ type: 'task', id } as const)),
            'tasksByUser',
          ]
        }
        return [...tasks.map(({ id }) => ({ type: 'task', id } as const))]
      },
    }),
    getTask: build.query<ParsedTaskFragment, Types.GetTaskVariables>({
      query: (variables) => ({ variables, document: GetTaskDocument }),
      transformResponse({ getTask }: Types.GetTask) {
        return parseTaskFragment(getTask)
      },
      providesTags: (res) => {
        const id = res?._id
        return id ? [{ id, type: 'task' }] : []
      },
    }),
    getTasksByTreatmentId: build.query<
      ParsedTaskFragment[],
      Types.GetTasksByTreatmentIdVariables
    >({
      query: (variables) => ({
        variables,
        document: GetTasksByTreatmentIdDocument,
      }),
      transformResponse({
        getTasksByTreatmentIds,
      }: Types.GetTasksByTreatmentId) {
        return getTasksByTreatmentIds.map(parseTaskFragment)
      },
      providesTags: (res, err, req) => {
        return [
          ...(res || []).map(({ _id }) => ({ type: 'task', id: _id } as const)),
          { type: 'tasksByTreatment', id: req.treatmentId },
        ]
      },
    }),
    createTask: build.mutation<
      ParsedTaskFragment,
      {
        content: Overwrite<
          Types.CreateTaskInput,
          { patientPreferences?: TaskPatientPreferences | null }
        >
      }
    >({
      query: ({ content: { patientPreferences, ...content } }) => ({
        variables: {
          content: {
            ...content,
            patientPreferences:
              patientPreferences && JSON.stringify(patientPreferences),
          },
        },
        document: CreateTaskDocument,
      }),
      transformResponse({ createTask }: Types.CreateTask) {
        return parseTaskFragment(createTask)
      },
      async onQueryStarted(payload, { dispatch, getState, queryFulfilled }) {
        const state = getState() as unknown as RootStateWithAuth
        const careManagerId =
          state[AUTH_REDUCER_KEY].session?.user.careManagerId
        const { patient } = payload.content
        const { data: createTask } = await queryFulfilled.catch(() => ({
          data: null,
        }))

        if (createTask) {
          taskApi.util
            .selectInvalidatedBy(getState() as any, [
              'tasksByUser',
              {
                type: 'tasksByTreatment',
                id: createTask?.treatmentId,
              } as const,
            ])
            .forEach(({ endpointName, originalArgs }) => {
              dispatch(
                taskApi.util.updateQueryData(
                  endpointName as any,
                  originalArgs,
                  (draft) => {
                    switch (endpointName) {
                      case 'getTasksByTreatmentId':
                        ;(draft as ParsedTaskFragment[]).push(createTask)
                        break
                      case 'searchTasks': {
                        const query = JSON.parse(originalArgs.query)
                        const queryType = getQueryType(query)
                        if (queryType === SearchTasksQueryType.assigneeTodo) {
                          if (
                            careManagerId === createTask.assignee._id &&
                            createTask.status === Types.TaskStatus.ToDo
                          ) {
                            ;(
                              draft as Types.SearchTasks
                            ).searchTasks?.tasks.push(
                              taskToSearchableTask(createTask, patient),
                            )
                          }
                        } else {
                          ;(draft as Types.SearchTasks).searchTasks?.tasks.push(
                            taskToSearchableTask(createTask, patient),
                          )
                        }
                        break
                      }
                      default:
                        break
                    }
                  },
                ),
              )
            })
        }
      },
    }),
    updateTask: build.mutation<
      ParsedTaskFragment,
      {
        content: Overwrite<
          Types.UpdateTaskInputV2,
          { patientPreferences?: TaskPatientPreferences | null }
        >
      }
    >({
      query: ({ content: { patientPreferences, ...content } }) => ({
        variables: {
          content: {
            ...content,
            patientPreferences:
              patientPreferences && JSON.stringify(patientPreferences),
          },
        },
        document: UpdateTaskDocument,
      }),
      transformResponse({ updateTaskV2 }: Types.UpdateTask) {
        return parseTaskFragment(updateTaskV2)
      },
      async onQueryStarted(payload, { dispatch, getState, queryFulfilled }) {
        const { data } = await queryFulfilled.catch(() => ({
          data: null,
        }))
        if (data) {
          taskApi.util
            .selectInvalidatedBy(getState() as any, [
              {
                type: 'task',
                id: payload.content.id,
              } as const,
            ])
            .forEach(({ endpointName, originalArgs }) => {
              dispatch(
                taskApi.util.updateQueryData(
                  endpointName as any,
                  originalArgs,
                  (draft) => {
                    switch (endpointName) {
                      case 'getTaskByTreatmentId': {
                        const tasks = (draft as ParsedTaskFragment[]) || []
                        const index = tasks.findIndex(
                          (task) => task._id === data._id,
                        )
                        tasks[index] = data
                        break
                      }
                      case 'getTask': {
                        ;(draft as ParsedTaskFragment) = data
                        break
                      }
                      case 'searchTasks': {
                        const tasks =
                          (draft as Types.SearchTasks).searchTasks.tasks || []
                        const index = tasks.findIndex(
                          (task) => task.id === data._id,
                        )
                        tasks[index] = {
                          ...tasks[index],
                          ...taskToSearchableTask(data),
                          patient: tasks[index]!.patient,
                        }
                        break
                      }
                      default:
                        break
                    }
                  },
                ),
              )
            })
        }
      },
    }),
    updateTasks: build.mutation<
      ParsedTaskFragment[],
      {
        content: Overwrite<
          Types.UpdateTaskInputV2,
          { patientPreferences?: TaskPatientPreferences | null }
        >[]
      }
    >({
      query: ({ content }) => ({
        variables: {
          content: content.map(({ patientPreferences, ...rest }) => ({
            ...rest,
            patientPreferences:
              patientPreferences && JSON.stringify(patientPreferences),
          })),
        },
        document: UpdateTasksDocument,
      }),
      transformResponse({ updateTasks }: Types.UpdateTasks) {
        return updateTasks.map(parseTaskFragment)
      },
      async onQueryStarted(payload, { dispatch, getState, queryFulfilled }) {
        const { data: updateTasks } = await queryFulfilled.catch(() => ({
          data: null,
        }))
        if (updateTasks && updateTasks.length) {
          taskApi.util
            .selectInvalidatedBy(
              getState() as any,
              updateTasks.map(
                ({ _id }) =>
                  ({
                    type: 'task',
                    id: _id,
                  } as const),
              ),
            )
            .forEach(({ endpointName, originalArgs }) => {
              dispatch(
                taskApi.util.updateQueryData(
                  endpointName as any,
                  originalArgs,
                  (draft) => {
                    switch (endpointName) {
                      case 'getTask': {
                        const updatedTask = updateTasks.find(
                          (v) => v._id === (draft as ParsedTaskFragment)._id,
                        )
                        if (updatedTask) {
                          draft = updatedTask
                        }
                        break
                      }
                      case 'getTasksByTreatmentId': {
                        const tasks = (draft as ParsedTaskFragment[]) || []
                        for (const updateTask of updateTasks) {
                          const index = tasks.findIndex(
                            (task) => task._id === updateTask._id,
                          )
                          if (index >= 0) {
                            tasks[index] = updateTask
                          }
                        }
                        break
                      }
                      case 'searchTasks': {
                        const tasks =
                          (draft as Types.SearchTasks).searchTasks.tasks || []
                        for (const updateTask of updateTasks) {
                          const index = tasks.findIndex(
                            (task) => task.id === updateTask._id,
                          )
                          if (index >= 0) {
                            tasks[index] = {
                              ...tasks[index],
                              ...taskToSearchableTask(updateTask),
                              patient: tasks[index]!.patient,
                            }
                          }
                        }
                        break
                      }
                      default:
                        break
                    }
                  },
                ),
              )
            })
        }
      },
    }),
    deleteTask: build.mutation<ParsedTaskFragment, Types.DeleteTaskVariables>({
      query: (variables) => ({ variables, document: DeleteTaskDocument }),
      transformResponse({ deleteTask }: Types.DeleteTask) {
        return parseTaskFragment(deleteTask)
      },
      async onQueryStarted(payload, { dispatch, getState, queryFulfilled }) {
        queryFulfilled
          .catch((err) => console.error(err))
          .then(
            () => {
              taskApi.util
                .selectInvalidatedBy(getState() as any, [
                  {
                    type: 'task',
                    id: payload._id,
                  } as const,
                ])
                .forEach(({ endpointName, originalArgs }) => {
                  dispatch(
                    taskApi.util.updateQueryData(
                      endpointName as any,
                      originalArgs,
                      (draft) => {
                        switch (endpointName) {
                          case 'getTasksByTreatmentId': {
                            const tasks = (draft as ParsedTaskFragment[]) || []
                            const index = tasks.findIndex(
                              (task) => task._id === payload._id,
                            )
                            if (index !== -1) {
                              tasks.splice(index, 1)
                            }
                            break
                          }
                          case 'searchTasks': {
                            const tasks =
                              (draft as Types.SearchTasks).searchTasks.tasks ||
                              []
                            const index = tasks.findIndex(
                              (task) => task.id === payload._id,
                            )
                            if (index !== -1) {
                              tasks.splice(index, 1)
                            }
                            break
                          }
                          default:
                            break
                        }
                      },
                    ),
                  )
                })
            },
            (err) => console.error(err),
          )
      },
    }),
  }),
})
export type TaskApiType = typeof taskApi
