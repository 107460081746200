import {
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@valerahealth/ui-components/base'
import { Close } from '@valerahealth/ui-components/icons'
import { format } from 'date-fns'
import { PatientLegalSex, careManagerApi } from '@valerahealth/rtk-query'
import { useTranslation } from '../locales'
import { formatIsoDate } from '@valerahealth/ui-components/utils/date'

const genderEnum = {
  [PatientLegalSex.M]: 'Male',
  [PatientLegalSex.F]: 'Female',
  [PatientLegalSex.O]: 'Other',
}

export default function NoteHeader({
  appointmentId,
  treatmentId,
  handleClose,
}: {
  appointmentId?: string
  treatmentId: string
  handleClose?: () => void
}) {
  const { t } = useTranslation()

  const { data: profile } =
    careManagerApi.useGetPatientProfileByTreatmentIdQuery({ id: treatmentId })

  const { data: appointment } = careManagerApi.useGetAppointmentQuery(
    {
      treatmentId,
      appointmentId: appointmentId!,
    },
    { skip: !appointmentId },
  )

  if (!profile) return <span />

  return (
    <Paper
      elevation={3}
      square
      sx={{
        display: 'flex',
        width: '100%',
        padding: '14px 0 14px 24px',
        justifyContent: 'space-between',
        height: '80px',
        backgroundColor: 'rgb(246 247 244)',
        marginBottom: '5px',
      }}
    >
      <Grid xs={5}>
        <Grid container sx={{ alignItems: 'baseline' }}>
          <Typography
            component="h4"
            sx={{ margin: '0', fontWeight: '500' }}
          >{`${profile.firstName} ${profile.lastName} `}</Typography>
          <Typography
            sx={{ color: 'text.secondary', marginLeft: '20px', fontSize: 12 }}
          >
            {`${t('MRN')} ${profile.mrn}`}
          </Typography>
        </Grid>
        <Grid
          container
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'baseline',
          }}
        >
          <Grid container xs={6}>
            <Typography
              sx={{
                color: 'text.secondary',
                marginRight: '5px',
                fontSize: 12,
              }}
            >
              {t('DOB')}:
            </Typography>
            <Typography sx={{ fontSize: 12 }}>
              {formatIsoDate(profile.dateOfBirth, 'MM/dd/yy')}
            </Typography>
          </Grid>
          <Grid container xs={6}>
            <Typography
              sx={{
                color: 'text.secondary',
                marginRight: '5px',
                fontSize: 12,
              }}
            >
              {t('legalSex')}:
            </Typography>
            <Typography sx={{ fontSize: 12 }}>
              {genderEnum[profile.gender]}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid xs={5}>
        <Grid container>
          <Typography
            sx={{ color: 'text.secondary', marginRight: '5px', fontSize: 13 }}
          >
            {t('provider')}:
          </Typography>
          <Typography sx={{ fontSize: 12 }}>
            {appointment?.provider
              ? `${appointment?.provider?.firstName} ${appointment?.provider?.lastName}`
              : '-'}
          </Typography>
        </Grid>
        <Grid container>
          <Typography
            sx={{ color: 'text.secondary', marginRight: '5px', fontSize: 12 }}
          >
            {t('date')}:
          </Typography>

          <Typography sx={{ fontSize: 12 }}>
            {appointment?.date
              ? `${format(
                  new Date(appointment?.date),
                  'MM/dd/yyyy, hh:mm:a',
                )} PST`
              : '-'}
          </Typography>
        </Grid>
      </Grid>
      <Grid>
        <IconButton
          title={t('close')}
          placement="left"
          color="secondary"
          onClick={() => {
            handleClose?.()
          }}
        >
          <Close />
        </IconButton>
      </Grid>
    </Paper>
  )
}
