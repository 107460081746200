import { useMemo, useEffect } from 'react'
import {
  type FieldArrayWithId,
  Select,
  useForm,
  useFieldArray,
  Combobox,
  DatePicker,
  validate,
  useWatch,
  SaveButton,
  TextField,
} from '@valerahealth/ui-components/form'
import {
  AddCircleOutline,
  RemoveCircleOutline,
  ContentCopy,
} from '@valerahealth/ui-components/icons'
import {
  Stack,
  useNotify,
  MenuItem,
  Button,
  Avatar,
  TextField as BaseTextField,
  IconButton,
  FormHelperText,
  Box,
  Comment,
  Link,
  Typography,
  useConfirmationDialog,
  WorkflowSidebarActions,
  WorkflowSidebarContent,
} from '@valerahealth/ui-components'
import {
  type DayTime,
  DayOfWeek,
  ResourceType,
  ReoccuranceType,
  ServiceTypeCode,
  ServiceCategoryCode,
  DAY_OF_WEEK_ARRAY,
  DAY_TO_NUMBER,
  schedulingApi,
  SCHEDULE_SERVICE_CATEGORIES,
  type ScheduleInput,
  type ScheduleFragment,
  practiceMgrApi,
} from '@valerahealth/rtk-query'
import {
  strToDate,
  indexOfOverlappingInterval,
  isoTimeToMinutes,
  minutesToIsoTime,
  formatIsoTime,
  generateTimeOptions,
  dateToDateTimeStrIgnoreTimezone,
  getTimezoneValues,
  compareDesc,
  TimeOption,
  isBetween,
  subYears,
  addYears,
} from '@valerahealth/ui-components/utils/date'
import { Trans } from '@valerahealth/ui-translation'
import FormProvider from '@valerahealth/ui-components/form/FormProvider'
import {
  Permission,
  checkPermissions,
  useReduxSelectorWithAuthState,
} from '@valerahealth/redux-auth'
import { useTranslation } from '../locales'
import ExpectedHoursProgressBar, {
  patientHoursList,
} from './ExpectedHoursProgressBar'
import { SCHEDULING_GUIDELINE_LINK } from '../utilities/constants'
import { useReduxDispatch, actions, CalendarProviderType } from '../reducer'

interface FormSchedule {
  serviceCategory: ServiceCategoryCode
  location: { name: string; id: string }[]
  timezone: string
  planningHorizon: {
    startDate: Date | null
    endDate: Date | null
  }
  dayTimes: (Omit<DayTime, 'startTime' | 'endTime'> & {
    startTime: TimeOption
    endTime: TimeOption
  })[]
  comment: ''
}

type Field = FieldArrayWithId<FormSchedule, 'dayTimes', 'id'>

/**
 * END TYPES
 */

/**
 * CONSTANTS
 */

const timezoneOptions = getTimezoneValues()

const timeOptions = generateTimeOptions(15)

const emptyDays = [] as {
  field: Field
  index: number
}[]

const serviceCategories = SCHEDULE_SERVICE_CATEGORIES

/** some styles to stay consistent between seperate elements */
const formGap = 2
const actionsIconStyle = { height: '1.75rem', width: '1.75rem' }

const daysOfWeekGroupings = {
  [DayOfWeek.Monday]: emptyDays,
  [DayOfWeek.Tuesday]: emptyDays,
  [DayOfWeek.Wednesday]: emptyDays,
  [DayOfWeek.Thursday]: emptyDays,
  [DayOfWeek.Friday]: emptyDays,
  [DayOfWeek.Saturday]: emptyDays,
  [DayOfWeek.Sunday]: emptyDays,
}

const defaultScheduleDayTimes = () =>
  DAY_OF_WEEK_ARRAY.slice(1, -1).map((dayOfWeek) => ({
    dayOfWeek,
    startTime: {
      iso: '09:00',
      display: formatIsoTime('09:00'),
    },
    endTime: {
      iso: '17:00',
      display: formatIsoTime('17:00'),
    },
  }))

const showLocationCondition = new Set([
  ServiceCategoryCode.Patient,
  ServiceCategoryCode.Cap,
  ServiceCategoryCode.Consult,
  ServiceCategoryCode.Toc,
  ServiceCategoryCode.Group,
  ServiceCategoryCode.Intake,
])

/**
 * END CONSTANTS
 */

/**
 * STAND ALONE FUNCTIONS
 */

const groupByDay = (fields: Field[]) =>
  fields.reduce(
    (sum, dayTime, index) => {
      sum[dayTime.dayOfWeek] = sum[dayTime.dayOfWeek].concat({
        field: dayTime,
        index,
      })
      return sum
    },
    { ...daysOfWeekGroupings },
  )

/** only including what is necessary in the form */
const scheduleToForm = (
  schedule?: ScheduleFragment,
  serviceCategoryCode?: ServiceCategoryCode,
): FormSchedule => ({
  location:
    schedule?.actors
      .filter(
        (
          value,
        ): value is {
          _id: string
          type: ResourceType.Location
          display: string
        } => {
          const { type, _id, display } = value
          return type === ResourceType.Location && !!_id && !!display
        },
      )
      .map(({ _id: id, display: name }) => ({ id, name })) || [],
  serviceCategory:
    schedule?.serviceCategory?.code ||
    serviceCategoryCode ||
    ServiceCategoryCode.Patient,
  timezone:
    schedule?.reoccuranceTemplate.timezone ||
    timezoneOptions.find(
      (t) => t.value === Intl.DateTimeFormat().resolvedOptions().timeZone,
    )?.value ||
    '',
  planningHorizon: {
    startDate: strToDate(
      schedule?.reoccuranceTemplate.planningHorizon.startDate,
    ),
    endDate: strToDate(schedule?.reoccuranceTemplate.planningHorizon.endDate),
  },
  dayTimes:
    schedule?.reoccuranceTemplate.weeklyTemplate![0]?.dayTimes?.map(
      ({ startTime, endTime, ...rest }) => ({
        startTime: {
          iso: startTime,
          display: formatIsoTime(startTime),
        },
        endTime: {
          iso: endTime,
          display: formatIsoTime(endTime),
        },
        ...rest,
      }),
    ) || defaultScheduleDayTimes(),
  comment: '',
})

const formToSchedule = (
  form: FormSchedule,
  providerId: string,
): ScheduleInput => ({
  active: true,
  actors: [
    {
      _id: providerId,
      type: ResourceType.Practitioner,
    },
  ].concat(
    form.location
      ? [
          ...form.location.map((item) => {
            return {
              _id: item.id,
              type: ResourceType.Location,
            }
          }),
        ]
      : [],
  ),
  // we aren't using service type on schedule but its required in the API
  serviceType: ServiceTypeCode.Int,
  serviceCategory: form.serviceCategory,
  subjectLimit: 1,
  reoccuranceTemplate: {
    timezone: form.timezone,
    reoccuranceType: ReoccuranceType.Weekly,
    planningHorizon: {
      startDate: dateToDateTimeStrIgnoreTimezone(
        form.planningHorizon.startDate,
      ),
      endDate:
        dateToDateTimeStrIgnoreTimezone(form.planningHorizon.endDate, true) ||
        undefined,
    },
    weeklyTemplate: [
      {
        interval: 1,
        offset: 1,
        dayTimes: form.dayTimes.map(
          ({
            dayOfWeek,
            startTime: { iso: startTime },
            endTime: { iso: endTime },
          }) => ({
            dayOfWeek,
            startTime,
            endTime,
          }),
        ),
      },
    ],
  },
  note: form.comment.trim(),
})

/**
 * END STAND ALONE FUNCTIONS
 */

const perms = [Permission.Schedule_Create, Permission.Schedule_Update]

export default function AddEditSchedule({
  schedule,
  serviceCategoryCode,
  provider,
}: {
  schedule?: ScheduleFragment
  serviceCategoryCode?: ServiceCategoryCode
  provider: CalendarProviderType
}) {
  const { t } = useTranslation()
  const notify = useNotify()
  const dispatch = useReduxDispatch()

  const providerLicenseStates = useMemo(() => {
    return provider?.licenses?.map((l) => l.stateCode) || []
  }, [provider])

  const { useCreateScheduleMutation, useUpdateScheduleMutation } = schedulingApi
  const [updateSchedule, updateScheduleRes] = useUpdateScheduleMutation()
  const [createSchedule, createScheduleRes] = useCreateScheduleMutation()

  const [canAdd, canUpdate] = useReduxSelectorWithAuthState((state) =>
    checkPermissions(state, perms),
  )

  const readOnly = schedule ? !canUpdate : !canAdd

  const { locations, isLoading: isLocationsLoading } =
    practiceMgrApi.useGetLocationsQuery(undefined, {
      selectFromResult: ({ data, isLoading }) => ({
        locations: data?.getLocations || [],
        isLoading,
      }),
    })

  const defaultValues = scheduleToForm(schedule, serviceCategoryCode)
  const methods = useForm({
    defaultValues,
  })
  const {
    formState: { errors },
  } = methods
  const { fields, append, remove, replace } = useFieldArray({
    control: methods.control,
    name: 'dayTimes',
    rules: {
      required: t('AddEditSchedule.dayTimesRequired'),
      validate: (values) => {
        // convert to dates
        const intervals = values
          // we have field level validation for this, and not having valid intervals breaks the underlying function, so let just remove invalid intervals
          .filter((v) => v.startTime.iso <= v.endTime.iso)
          .map(({ startTime, endTime, dayOfWeek }) => ({
            start: new Date(
              0,
              0,
              DAY_TO_NUMBER[dayOfWeek],
              0,
              isoTimeToMinutes(startTime.iso),
            ),
            end: new Date(
              0,
              0,
              DAY_TO_NUMBER[dayOfWeek],
              0,
              isoTimeToMinutes(endTime.iso),
            ),
          }))
        const overlapIndex = indexOfOverlappingInterval(intervals)
        if (overlapIndex > -1) {
          const { dayOfWeek, startTime, endTime } = values[overlapIndex]!
          return t('AddEditSchedule.dayTimesOverlappingDate', {
            dayOfWeek: t(`DayOfWeek.${dayOfWeek}`),
            startTime: startTime.display,
            endTime: endTime.display,
          })
        }
        return true
      },
    },
  })
  // used to display under each grouped day, note that fields and grouped fields only cause a rerender on field added/removed. If you edit a field value it does not cause rerender
  const groupedFields = useMemo(() => groupByDay(fields), [fields])

  // we only should show the copy button if more than one day has dayTimes set
  const shouldRenderCopyButton = useMemo(() => {
    const daysWithSchedule = Object.values(groupedFields).filter(
      (v) => v.length > 0,
    ).length
    return daysWithSchedule > 1
  }, [groupedFields])

  const comments = useMemo(() => {
    if (!schedule?.notes?.length) return null
    return schedule.notes
      .slice()
      .sort((a, b) => compareDesc(new Date(a.time), new Date(b.time)))
  }, [schedule?.notes])

  const serviceCategory = methods.watch('serviceCategory')

  useEffect(() => {
    if (serviceCategory === ServiceCategoryCode.OutOfOffice) {
      dispatch(
        actions.openView({
          type: 'appointmentForm',
          mode: 'add',
          code: ServiceCategoryCode.OutOfOffice,
          providerId: provider._id,
        }),
      )
    }
  }, [serviceCategory, provider._id, dispatch])

  const dayTimeValues = useWatch({
    name: 'dayTimes',
    control: methods.control,
  })
  const dayTimes = useMemo(
    () =>
      dayTimeValues.map(
        ({ startTime: { iso: startTime }, endTime: { iso: endTime } }) => ({
          startTime,
          endTime,
        }),
      ),
    [dayTimeValues],
  )

  const expectedHours = useMemo(
    () =>
      provider?.expectedHours
        ?.filter(
          (v) =>
            ((patientHoursList.has(serviceCategory) &&
              v.serviceCategory.code === ServiceCategoryCode.Patient) ||
              v.serviceCategory.code === serviceCategory) &&
            isBetween(
              {
                start: v.startDate
                  ? (strToDate(v.startDate) as Date)
                  : subYears(new Date(), 100),
                end: v.endDate
                  ? (strToDate(v.endDate, true) as Date)
                  : addYears(new Date(), 100),
              },
              new Date(),
            ),
        )
        .sort((a, b) => a.weeklyMinutesTotal - b.weeklyMinutesTotal)[0],
    [serviceCategory, provider],
  )

  const copyDayToAll = (day: DayOfWeek) => {
    // get the dayTimes of the current day
    const dayTimesToCopy = dayTimeValues.filter((v) => v.dayOfWeek === day)
    // get every other day that has values
    const otherDaysWithFields = Object.entries(groupedFields)
      .filter(([dayOfWeek, fields]) => fields.length > 0 && dayOfWeek !== day)
      .map(([day]) => day as DayOfWeek)
    replace(
      // copy to all other days
      dayTimesToCopy.concat(
        ...otherDaysWithFields.map((dayOfWeek) =>
          dayTimesToCopy.map(({ startTime, endTime }) => ({
            startTime,
            endTime,
            dayOfWeek,
          })),
        ),
      ),
    )
  }

  const addNewDayTime = (day: DayOfWeek) => {
    const lastTime = dayTimeValues
      .filter((d) => d.dayOfWeek === day)
      .map((val) => val.endTime.iso)
      .sort()
      .pop()
    let startMinutes = isoTimeToMinutes(lastTime || '09:00')
    // if there is a last time then lets choose 1 hour after as the start of the next time window
    if (lastTime) startMinutes += 60
    // if no lastTime then we are going to
    const end = minutesToIsoTime(lastTime ? startMinutes + 60 : 17 * 60)
    const start = minutesToIsoTime(startMinutes)
    append({
      startTime: {
        iso: start,
        display: formatIsoTime(start),
      },
      endTime: {
        iso: end,
        display: formatIsoTime(end),
      },
      dayOfWeek: day,
    })
  }

  const onFormValidationFail = () => {
    notify({
      message: t('form_invalid'),
      severity: 'warning',
    })
  }

  const { confirm, ConfirmationDialog } = useConfirmationDialog()

  const onSubmit = async (values: FormSchedule) => {
    const formSchedule = formToSchedule(values, provider._id)

    const selectedLocations = locations.filter(({ id }) =>
      values.location.some((l) => l.id === id),
    )

    const locationsWithoutLicenses = selectedLocations.filter(
      (l) => !providerLicenseStates.includes(l.address.state),
    )
    if (locationsWithoutLicenses.length) {
      const locationNames = locationsWithoutLicenses
        .map((l) => l.name)
        .join(', ')
      const locationStates = locationsWithoutLicenses
        .map((l) => l.address.state)
        .filter((v, i, arry) => arry.indexOf(v) === i)
        .join(', ')

      const confirmed = await confirm({
        header: t('AddEditSchedule.notLicensedAtLocationWarningHeader'),
        body: (
          <Trans
            t={t}
            i18nKey="AddEditSchedule.notLicensedAtLocationWarningBody"
            values={{ locationNames, locationStates }}
            components={[<Link to="../licenses" target="_blank" />]}
          />
        ),
        confirmLabel: t('submit'),
        confirmButtonColor: 'warning',
      })
      if (!confirmed) return
    }

    const res = schedule
      ? await updateSchedule({ id: schedule._id, content: formSchedule })
      : await createSchedule({ content: formSchedule })

    if ('error' in res) {
      notify({
        message: t('api.schedule.saveFailure'),
        severity: 'error',
      })
    } else {
      notify({
        message: t('api.schedule.saveSuccess'),
        severity: 'success',
      })

      const schedule =
        'createSchedule' in res.data
          ? res.data.createSchedule
          : res.data.updateSchedule
      dispatch(
        actions.openView({
          type: 'scheduleForm',
          mode: 'edit',
          schedule,
          provider,
        }),
      )

      methods.reset(
        { ...values, comment: '' },
        {
          keepIsSubmitted: true,
          keepSubmitCount: true,
        },
      )
    }
  }

  return (
    <>
      <FormProvider {...methods} readOnly={readOnly}>
        <Stack
          component="form"
          sx={{ height: '100%' }}
          onSubmit={methods.handleSubmit(onSubmit, onFormValidationFail)}
        >
          <WorkflowSidebarContent>
            <Stack spacing={3}>
              <Stack>
                <Select
                  label={t('serviceCategory')}
                  name="serviceCategory"
                  required
                  disabled={!!schedule}
                >
                  {serviceCategories.map(({ code, text }) => (
                    <MenuItem key={code} value={code}>
                      {text}
                    </MenuItem>
                  ))}
                </Select>
                <Typography
                  sx={{
                    display: 'inline',
                    m: '10px 0px -6px 2px',
                    color: (theme) => theme.palette.text.primary,
                    fontSize: (theme) => theme.typography.body2,
                    '&:link, &:visited, &:hover, &:active': {
                      textDecoration: 'none',
                    },
                  }}
                >
                  {t('Refer to ')}
                  <Link
                    sx={{ color: (theme) => theme.palette.text.primary }}
                    target="_blank"
                    to={SCHEDULING_GUIDELINE_LINK}
                  >
                    {t('Scheduling Guidelines')}
                  </Link>
                  {t(' as needed.')}
                </Typography>
              </Stack>
              {showLocationCondition.has(serviceCategory) && (
                <Combobox
                  name="location"
                  label={t('location')}
                  required
                  multiple
                  fullWidth
                  loading={isLocationsLoading}
                  getOptionLabel={(o) => o.name}
                  isOptionEqualToValue={(o, v) => o.id === v.id}
                  options={locations}
                />
              )}
              <Stack gap={formGap}>
                {errors?.dayTimes?.root?.message && (
                  <FormHelperText error>
                    {errors?.dayTimes?.root?.message}
                  </FormHelperText>
                )}
                {DAY_OF_WEEK_ARRAY.map((day) => {
                  const hasValues = !!groupedFields[day].length
                  const addButton = (
                    <IconButton
                      size="small"
                      title={t('AddEditSchedule.dayTimeAddHelper')}
                      onClick={() => addNewDayTime(day)}
                    >
                      <AddCircleOutline sx={actionsIconStyle} />
                    </IconButton>
                  )
                  // used to create the same space as
                  const iconPlaceholder = (
                    <IconButton size="small" sx={{ pointerEvents: 'none' }}>
                      <Box sx={{ ...actionsIconStyle }} />
                    </IconButton>
                  )
                  return (
                    <Stack
                      direction="row"
                      alignItems="flex-start"
                      flexWrap="nowrap"
                      gap={1}
                      key={day}
                    >
                      <Avatar
                        sx={{
                          fontSize: '.7em',
                          backgroundColor: (theme) =>
                            theme.palette.secondary.dark,
                          opacity: (theme) =>
                            hasValues
                              ? 1
                              : theme.palette.action.disabledOpacity,
                        }}
                      >
                        {t(`DayOfWeek.abbreviated.${day}`).toUpperCase()}
                      </Avatar>
                      {hasValues ? (
                        <Stack gap={formGap} flexGrow={1}>
                          {groupedFields[day].map(
                            ({ field, index }, groupedIndex) => {
                              return (
                                <Stack
                                  key={field.id}
                                  direction="row"
                                  alignItems="center"
                                  gap={1}
                                  width="100%"
                                  flexWrap="nowrap"
                                >
                                  <Combobox
                                    size="small"
                                    fullWidth
                                    name={`dayTimes.${index}.startTime`}
                                    label={t('startTime')}
                                    getOptionLabel={(o) => o.display}
                                    isOptionEqualToValue={(o, v) =>
                                      o.iso === v.iso
                                    }
                                    options={timeOptions}
                                    required
                                    deps={`dayTimes.${index}.endTime`}
                                    disableClearable
                                    hideLabel
                                  />
                                  <Combobox
                                    size="small"
                                    fullWidth
                                    name={`dayTimes.${index}.endTime`}
                                    label={t('endTime')}
                                    getOptionLabel={(o) => o.display}
                                    isOptionEqualToValue={(o, v) =>
                                      o.iso === v.iso
                                    }
                                    options={timeOptions}
                                    required
                                    disableClearable
                                    hideLabel
                                    validate={(endTime: TimeOption) => {
                                      const startTime = methods.getValues(
                                        `dayTimes.${index}.startTime`,
                                      )
                                      if (
                                        startTime &&
                                        endTime &&
                                        startTime.iso >= endTime.iso
                                      ) {
                                        return t(
                                          'form_date1MustBeGreaterThanDate2',
                                          {
                                            date1: t('endTime'),
                                            date2: t('startTime'),
                                          },
                                        )
                                      }
                                      return true
                                    }}
                                  />
                                  {!readOnly && (
                                    <Stack direction="row" flexGrow={0}>
                                      {groupedIndex === 0
                                        ? addButton
                                        : iconPlaceholder}
                                      <IconButton
                                        size="small"
                                        onClick={() => remove(index)}
                                        title={t(
                                          'AddEditSchedule.dayTimeRemoveHelper',
                                        )}
                                      >
                                        <RemoveCircleOutline
                                          sx={actionsIconStyle}
                                        />
                                      </IconButton>
                                      {groupedIndex === 0 &&
                                      shouldRenderCopyButton ? (
                                        <IconButton
                                          size="small"
                                          title={t(
                                            'AddEditSchedule.dayTimeCopyHelper',
                                          )}
                                          onClick={() => copyDayToAll(day)}
                                        >
                                          <ContentCopy sx={actionsIconStyle} />
                                        </IconButton>
                                      ) : (
                                        iconPlaceholder
                                      )}
                                    </Stack>
                                  )}
                                </Stack>
                              )
                            },
                          )}
                        </Stack>
                      ) : (
                        <>
                          <BaseTextField
                            size="small"
                            disabled
                            label={t('unavailable')}
                            fullWidth
                          />
                          {!readOnly && (
                            <Stack direction="row">
                              {addButton}
                              {iconPlaceholder}
                              {iconPlaceholder}
                            </Stack>
                          )}
                        </>
                      )}
                    </Stack>
                  )
                })}
              </Stack>

              <Select label={t('timezone')} name="timezone" required>
                {timezoneOptions.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>

              <Stack direction="row" gap={formGap}>
                <DatePicker
                  label={t('startDate')}
                  name="planningHorizon.startDate"
                  required
                  deps={['planningHorizon.endDate']}
                  format="MMM d, yyyy"
                  disablePast
                  readOnly={readOnly}
                />
                <DatePicker
                  label={t('endDate')}
                  name="planningHorizon.endDate"
                  validate={(date1: Date | null) => {
                    const date2 = methods.getValues('planningHorizon.startDate')
                    return validate.dateOneGreaterThanDate2({
                      date1,
                      date1TArg: 'endDate',
                      date2,
                      date2TArg: 'startDate',
                      t,
                    })
                  }}
                  format="MMM d, yyyy"
                  disablePast
                  readOnly={readOnly}
                />
              </Stack>

              <TextField name="comment" label={t('comment')} multiline />

              {comments && (
                <Stack gap={1}>
                  {comments.map(
                    ({ text, time, authorReference: { display } }) => (
                      <Comment
                        key={time}
                        author={display}
                        text={text}
                        date={new Date(time)}
                      />
                    ),
                  )}
                </Stack>
              )}

              {expectedHours && (
                <ExpectedHoursProgressBar
                  intervals={dayTimes}
                  expectedHours={expectedHours}
                />
              )}
            </Stack>
          </WorkflowSidebarContent>
          <WorkflowSidebarActions>
            <Button
              variant="text"
              onClick={() =>
                // if there is no id then we navigated staight from the calendar rather than the provider list, so cancel should go back to the calendar
                serviceCategoryCode
                  ? dispatch(
                      actions.openView({
                        type: 'scheduleList',
                        code: serviceCategoryCode,
                        provider,
                      }),
                    )
                  : dispatch(actions.closeView())
              }
            >
              {t('cancel')}
            </Button>
            <SaveButton
              disabled={isLocationsLoading}
              isError={updateScheduleRes.isError || createScheduleRes.isError}
              isSuccess={
                updateScheduleRes.isSuccess || createScheduleRes.isSuccess
              }
              label={t('submit')}
            />
          </WorkflowSidebarActions>
        </Stack>
      </FormProvider>
      <ConfirmationDialog />
    </>
  )
}
