import { useCallback, useEffect, useMemo, useState } from 'react'
import { PatientStatus } from '@valerahealth/rtk-query'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from '@valerahealth/ui-translation'
import {
  Typography,
  Divider,
  Grid,
  Box,
  useNotify,
  Stack,
  setIntlPhoneNumber,
} from '@valerahealth/ui-components'
import {
  FloatingSaveButton,
  FormProvider,
  useForm,
} from '@valerahealth/ui-components/form'
import {
  toggleRestoreTreatmentPopup,
  updatePatient,
} from '../../redux/actions/treatmentRoom'
import { getTreatment } from '../utilities/treatments'
import './style.less'
import PatientDetails from './PatientDetails'
import OnBoarding from './OnBoarding'
import AppUsage from './AppUsage'
import Contacts from './Contacts'
import PeripheralDevices from './PeripheralDevices'
import { DischargePopup } from '../common/PatientDischargeForm'
import PhoneAlreadyExist from '../common/PhoneAlreadyExist'
import { SIDEBAR_WIDTH } from '../TreatmentRoom'
import { getTreatmentServerObject, getProfileObject } from './utilities'

const defaultProfileValues = {
  firstName: null,
  middleName: '',
  lastName: '',
  mrn: '',
  dateOfBirth: null,
  gender: '',
  preferredName: '',
  pronouns: '',
  genderIdentity: '',
  genderDetails: '',
  primaryPhone: '',
  primaryEmail: '',
  addressComponents: { street: '', city: '', state: '', zipcode: '' },
  timezone: '',
  phqScore: '',
  gad7Score: '',
  username: '',
  phone: '',
  email: '',
  scaleId: '',
}

const TreatmentBasicInfo = ({ treatment }) => {
  const { openSidebar } = useSelector((state) => state.global)
  const dispatch = useDispatch()
  const notify = useNotify()

  const { t } = useTranslation()
  const [profile, setProfile] = useState(defaultProfileValues)
  const [displayAlreadyExist, setDisplayAlreadyExist] = useState()
  const [handleCloseDischarge, setHandleCloseDischarge] = useState({
    active: false,
  })

  const methods = useForm({
    defaultValues: defaultProfileValues,
  })

  const integrationLink = profile?.integration?.fitbitIntegrationLink
  const peripheralDevice = useCallback(
    () => <PeripheralDevices link={integrationLink} />,
    [integrationLink],
  )

  const sections = useMemo(
    () => [
      {
        title: t('patientDetails'),
        component: PatientDetails,
      },
      {
        title: t('onboarding'),
        component: OnBoarding,
      },
      {
        title: t('valeraAppUsage'),
        component: AppUsage,
      },
      {
        title: t('contacts'),
        component: Contacts,
      },
      {
        title: t('peripheralDevices'),
        component: peripheralDevice,
      },
    ],
    [peripheralDevice, t],
  )

  useEffect(() => {
    if (treatment && treatment.profile) {
      if (!treatment.profile.dateOfBirth) {
        return
      }

      if (treatment.id) {
        const t = getProfileObject(treatment.profile)
        if (
          t.address &&
          (!t.addressComponents || !Object.keys(t.addressComponents).length)
        ) {
          t.addressComponents = {
            street: t.address,
          }
        }

        setProfile(t)
        methods.reset(t)
      }
    }
  }, [treatment, methods])

  const updateData = (data) => {
    const d = getTreatmentServerObject({ ...data, id: treatment.id })

    dispatch(
      updatePatient({
        patientData: d,
        treatmentId: treatment.id,
        errorCb: (errorKey, message) => {
          if (errorKey) {
            if (errorKey === 'already_exists_in_same_clinic') {
              setDisplayAlreadyExist({ sameClinic: true, data })
            } else if (errorKey === 'already_exists_in_different_clinic') {
              setDisplayAlreadyExist({ sameClinic: false, data })
            } else {
              methods.setError(
                errorKey,
                message ? { type: 'custom', message } : undefined,
              )
            }
          }
        },
      }),
    )
  }

  const isDischarged = (status) => {
    return (
      ![PatientStatus.ClosedDischarged].includes(profile.status) &&
      [PatientStatus.ClosedDischarged].includes(status)
    )
  }

  const wasDischarged = (status) => {
    return (
      [PatientStatus.ClosedDischarged].includes(profile.status) &&
      ![PatientStatus.ClosedDischarged].includes(status)
    )
  }

  const onFormSubmit = (data) => {
    const cb = () => {
      const phoneNumber = setIntlPhoneNumber(data.primaryPhone)
      if (phoneNumber) updateData({ ...data, primaryPhone: phoneNumber })
      else {
        methods.setError('primaryPhone')
      }
    }

    if (isDischarged(data.status)) {
      setHandleCloseDischarge({
        active: true,
        callback: (hasBeenDischarged) => {
          setHandleCloseDischarge({ active: false })

          if (hasBeenDischarged) {
            cb()
          } else if (hasBeenDischarged === false) {
            onFormSubmit({
              ...data,
              status: profile.status,
            })
          }
        },
      })
    } else if (wasDischarged(data.status)) {
      dispatch(
        toggleRestoreTreatmentPopup(true, {
          handleSuccess: cb,
          handleCancel: () => {
            onFormSubmit({
              ...data,
              status: profile.status,
            })
          },
        }),
      )
    } else {
      cb()
    }
  }

  const onFormValidationFail = () => {
    notify({
      message: t('form_invalid'),
      severity: 'warning',
    })
  }

  return (
    <>
      {displayAlreadyExist && (
        <PhoneAlreadyExist
          handleClose={() => {
            setDisplayAlreadyExist()
          }}
          sameClinic={displayAlreadyExist?.sameClinic}
          handleContinue={() => {
            updateData({ ...displayAlreadyExist.data, forceMultiPhone: true })
          }}
        />
      )}
      <DischargePopup
        open={!!handleCloseDischarge?.active}
        treatmentId={treatment.id}
        onCancel={() => {
          setHandleCloseDischarge({ active: false })
        }}
        onSuccess={() => {
          handleCloseDischarge.callback(true)
        }}
      />
      <Typography style={{ overflow: 'auto' }}>
        <Grid className="initial-css" sx={{ p: 3 }}>
          <FormProvider {...methods}>
            <Stack
              position="relative"
              spacing={3}
              component="form"
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  return false
                }
              }}
              onSubmit={methods.handleSubmit(
                onFormSubmit,
                onFormValidationFail,
              )}
            >
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="h5" gutterBottom component="div">
                    {t('basicInfo')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                sx={{ padding: '20px 10px', m: 0 }}
                style={{ marginTop: 0 }}
              >
                {sections.map((section) => {
                  const Section = section.component

                  return (
                    <Box key={section.title}>
                      <Typography gutterBottom variant="h6">
                        {section.title}
                      </Typography>
                      <Grid sx={{ p: 2 }} style={{ position: 'relative' }}>
                        <Section />
                      </Grid>
                      <Divider sx={{ mb: 4, mt: 4 }} />
                    </Box>
                  )
                })}
              </Grid>
              <FloatingSaveButton
                boxSx={
                  openSidebar
                    ? {
                        right: `${SIDEBAR_WIDTH + 2}%`,
                      }
                    : undefined
                }
                sx={{
                  opacity: 1,
                }}
                label={t('save')}
              />
            </Stack>
          </FormProvider>
        </Grid>
      </Typography>
    </>
  )
}

function mapStateToProps(state) {
  const { configurations } = state.program

  return {
    treatment: getTreatment(state) || {},
    displayFitbitIntegrationLink: configurations.fitbitIntegration,
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentBasicInfo)
