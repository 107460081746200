import { AnyAction, ThunkMiddleware } from '@reduxjs/toolkit'
import { type AuthActionPayloads } from '@valerahealth/redux-auth'
import { parseIntlPhoneNumber } from '@valerahealth/ui-components'
import { careManagerApi, configApi } from '@valerahealth/rtk-query'
import * as actions from 'redux/actions/actionTypes'
import { setRoleId, setUserId } from '../api/storageConfig'
import { type RootState } from './reducers'
import { loadNotifications } from './actions/caseloadActions'
import { careManagerEnhancedApi } from 'api/careManagerAPIEnhanced'

const {
  getProgramSettings: getCMProgramSettings,
  getUserSettings,
  getTreatment,
  getPatientProfileByTreatmentId,
  getEmrStatus,
  setChannelSeen,
} = careManagerApi.endpoints

const { getProgramSettings: getConfigProgramSettings } = configApi.endpoints

let previousProgramId: string | undefined
let previousCareMgrId: string | undefined
/** any settings we need to retrieve after the user successfully signs in should be dispatched here */
export const maintainSettingsMiddleware: ThunkMiddleware<
  RootState,
  AnyAction
> =
  ({ getState, dispatch }) =>
  (next) =>
  (action: AuthActionPayloads) => {
    // if we are logging out, store the previous program/careManagerId
    if (action.type === 'auth/setAuthState' && !action.payload) {
      const { programId, careManagerId } = getState().auth.session?.user || {}
      previousProgramId = programId
      previousCareMgrId = careManagerId
    }
    // apply the action
    next(action)

    // if we are getting a new token... check previous ids to see if we need to reset app state
    if (action.type === 'auth/setAuthState' && !!action.payload) {
      const { programId, careManagerId } = action.payload.user
      const hasProgramIdChanged =
        !!previousProgramId && previousProgramId !== programId
      const hasCareManagerIdChanged =
        !!previousCareMgrId && careManagerId !== previousCareMgrId
      if (hasProgramIdChanged) {
        console.log(
          'Sign in with new program id detected. Resetting app state.',
        )
        // many api calls scope data to the program in our tokens and so we need to completely refresh
        window.location.reload()
      }
      // if we haven't initiated for the given program, trigger
      if (getCMProgramSettings.select(programId)(getState()).isUninitialized) {
        dispatch(
          getCMProgramSettings.initiate(programId, {
            subscribe: true,
          }),
        )
      }

      // no args for this endpoint so if uninitialized or if program id has changed, trigger
      if (
        getConfigProgramSettings.select()(getState()).isUninitialized ||
        hasProgramIdChanged
      ) {
        dispatch(
          getConfigProgramSettings.initiate(undefined, {
            subscribe: true,
          }),
        )
      }

      if (
        hasCareManagerIdChanged ||
        getUserSettings.select()(getState()).isUninitialized
      ) {
        dispatch(
          getUserSettings.initiate(undefined, {
            subscribe: true,
          }),
        )
      }
      if (
        careManagerId &&
        (hasCareManagerIdChanged ||
          careManagerApi.endpoints.getNotifications.select({careManagerId})(getState()).isUninitialized)
      ) {
        dispatch(
          careManagerApi.endpoints.getNotifications.initiate({
            careManagerId: careManagerId!,
          }),
        )
      }
    }
  }

/** Applies data retrieved in rtk query to legacy redux store state to mainain old areas of the application */
export const applyLegacyStateUpdates: ThunkMiddleware<RootState, AnyAction> =
  ({ dispatch }) =>
  (next) =>
  (action: AnyAction) => {
    next(action)
    switch (true) {
      case getCMProgramSettings.matchFulfilled(action):
        dispatch({
          type: actions.LOAD_PROGRAM_CONFIGURATION_SUCCESS,
          configurations: action.payload,
        })
        break
      case getUserSettings.matchFulfilled(action):
        setUserId(action.payload.id)
        setRoleId(action.payload.profile.id)
        dispatch({
          type: actions.GET_USER_SUCCESS,
          data: action.payload,
        })
        dispatch(
          loadNotifications({
            nextPage: undefined,
            hideLoader: undefined,
            cb: undefined,
          }),
        )
        break
      case getTreatment.matchFulfilled(action):
        dispatch({
          type: actions.LOAD_TREATMENT_SUCCESS,
          treatment: action.payload,
        })
        break
      case getPatientProfileByTreatmentId.matchFulfilled(action):
        dispatch({
          type: actions.LOAD_PROFILE_SUCCESS,
          id: action.meta.arg.originalArgs.id,
          profile: {
            ...action.payload,
            phone: parseIntlPhoneNumber(action.payload.phone),
            primaryPhone: action.payload.primaryPhone
              ? parseIntlPhoneNumber(action.payload.primaryPhone)
              : undefined,
          },
        })
        break
      case getEmrStatus.matchFulfilled(action):
        dispatch({
          type: actions.LOAD_EMR_STATUS,
          status: action.payload,
          selectedId: action.meta.arg.originalArgs.treatmentId,
        })
        break
      case setChannelSeen.matchFulfilled(action):
        dispatch({
          type: actions.SET_CHANNEL_SEEN,
          treatmentId: action.meta.arg.originalArgs.treatmentId,
        })
        break
      // REMOVE ONCE WE HAVE RTK QUERY TAKE OVER UPDATE PROFILE AND RESTORE PATIENT
      case action.type === actions.UPDATE_PROFILE_SUCCESS:
      case action.type === actions.RESTORE_PATIENT: {
        if (action.id) {
          dispatch(
            careManagerApi.util.invalidateTags([
              { type: 'Treatment', id: action.id },
              { type: 'PatientProfile', id: action.id },
            ]),
          )
        }
        break
      }

      default:
    }
  }
