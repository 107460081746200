import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { AggregationsAggregationContainer } from '@opensearch-project/opensearch/api/types'
import {
  IconButton,
  EditIcon,
  Typography,
  Button,
  Box,
  Link,
  useNotify,
} from '@valerahealth/ui-components'
import { Add, OpenInNew } from '@valerahealth/ui-components/icons'
import {
  OperatorValueStr,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
  PersistentDataGrid,
  renderHoverFullContentCell,
  HoverFullContentCell,
  GridRowSelectionModel,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridInitialState,
  type ServerSideColumn,
  useServerSideGrid,
  GridFilterItem,
  OperatorValueSingleSelect,
  handleStringOsFilter,
  getOsGridStringOperators,
  getOsGridSingleSelectOperators,
  usePersistentGridInitialState,
  defaultQuickFilterSortOverride,
} from '@valerahealth/ui-components/grid'
import {
  format,
  isPast,
  strToDate,
} from '@valerahealth/ui-components/utils/date'
import {
  TaskServiceType,
  TaskStatus,
  TaskType,
  SearchableTaskFragment,
  SearchableReferenceFragment,
  searchableTaskToRegularTask,
  taskApi,
  referenceDataApi,
} from '@valerahealth/rtk-query'
import { useTranslation } from '@valerahealth/ui-translation'
import T_LABELS from 'locales/en'
import { ColumnKey } from '../TaskForm/types'
import { DeleteButton, TaskStatusChip } from '../TaskForm/Fields'

const filterOldTasks = {
  // always filter out tasks older than 30 days that are void or complete
  must_not: [
    {
      bool: {
        must: [
          { term: { 'status.keyword': TaskStatus.Void } },
          {
            range: {
              lastUpdatedDate: {
                lte: 'now-30d/d',
              },
            },
          },
        ],
      },
    },
    {
      bool: {
        must: [
          { term: { 'status.keyword': TaskStatus.Completed } },
          {
            range: {
              lastUpdatedDate: {
                lte: 'now-30d/d',
              },
            },
          },
        ],
      },
    },
  ],
}

function statusToOs(status: string, field: string) {
  switch (status) {
    case TaskStatus.Overdue:
      return {
        bool: {
          must: [
            { term: { [`${field}.keyword`]: TaskStatus.ToDo } },
            {
              range: {
                dueDate: {
                  lt: 'now/d',
                },
              },
            },
          ],
        },
      }
    case TaskStatus.ToDo:
      return {
        bool: {
          must: [
            { term: { [`${field}.keyword`]: TaskStatus.ToDo } },
            {
              range: {
                dueDate: {
                  gte: 'now/d',
                },
              },
            },
          ],
        },
      }
    default:
      return { term: { [`${field}.keyword`]: status } }
  }
}

const handleTaskStatusFilter = (item: GridFilterItem, field: string) => {
  const { operator, value: _value } = item

  let value: string[] = []
  if (Array.isArray(_value))
    value = _value
      .filter((v): v is { value: string } => Object.hasOwn(v || {}, 'value'))
      .map((v) => v.value)
  else if (
    Object.hasOwn(_value || {}, 'value') &&
    typeof _value.value === 'string'
  )
    value = [_value.value]
  if (!value.length) return undefined

  switch (true) {
    case operator === OperatorValueSingleSelect.isAnyOf && value.length > 1:
      return {
        bool: {
          minimum_should_match: 1,
          should: value.map((v) => statusToOs(v, field)),
        },
      }
    case operator === OperatorValueSingleSelect.isAnyOf ||
      operator === OperatorValueSingleSelect.is:
      return statusToOs(value[0]!, field)
    case operator === OperatorValueSingleSelect.not:
      return {
        bool: { must_not: statusToOs(value[0]!, field) },
      }
    default:
      return undefined
  }
}

export type AggsResultType = {
  assigneeAggs: {
    buckets: { key: string; doc_count: number }[]
  }
  reportedAggs: {
    buckets: { key: string; doc_count: number }[]
  }
}

const { useSearchTasksQuery, useGetEnhancedSearchTaskAggregatesQuery } = taskApi

const quickFilterFields = ['patient.display', 'patient.mrn', 'assignee.display']

const patientStr = (patient: SearchableTaskFragment['patient']) =>
  patient ? [patient.display, patient.mrn].filter((v) => v).join(' - ') : ''
const referenceStr = (ref: SearchableReferenceFragment) =>
  `${ref.display ?? ''}${ref.active === false ? ' (inactive)' : ''}`

const ReferenceGridCell = ({
  reference,
  display,
  active,
  computedWidth,
}: SearchableReferenceFragment & { computedWidth: number }) => {
  const { t } = useTranslation()
  return (
    <HoverFullContentCell
      width={computedWidth}
      value={
        <Box
          display="flex"
          flexDirection="column"
          onClick={(e) => e.stopPropagation()}
        >
          {reference ? (
            <Link
              underline="none"
              target="_blank"
              to={reference}
              sx={{
                color: (theme) => theme.palette.secondary.dark,
              }}
            >
              {display || ''}&nbsp;{' '}
              <OpenInNew color="inherit" sx={{ height: '1rem' }} />
            </Link>
          ) : (
            <Typography
              sx={{
                fontSize: (theme) => theme.typography.body2,
              }}
            >
              {display || ''}
            </Typography>
          )}
          {active === false && (
            <Typography
              sx={{
                fontSize: (theme) => theme.typography.body2,
                opacity: 0.5,
              }}
            >
              {`(${t('Deactivated')})`}
            </Typography>
          )}
        </Box>
      }
    />
  )
}

const taskToRow = (searchableTask: SearchableTaskFragment) => {
  const {
    type,
    status,
    description,
    dueDate,
    referralSource,
    serviceType,
    assignee,
    createdBy,
    createdDate,
    patient,
    treatmentId,
    recentComments,
  } = searchableTask
  return {
    id: searchableTask.id,
    treatmentId,
    _task: searchableTaskToRegularTask(searchableTask),
    [ColumnKey.type]: type,
    [ColumnKey.status]: status,
    [ColumnKey.description]: description,
    [ColumnKey.dueDate]: strToDate(dueDate, true),
    [ColumnKey.createdDate]: new Date(createdDate),
    [ColumnKey.referralSource]: referralSource || '',
    [ColumnKey.serviceType]: serviceType || '',
    [ColumnKey.patient]: {
      ...patient,
    },
    [ColumnKey.assignee]: assignee,
    [ColumnKey.createdBy]: createdBy,
    [ColumnKey.lastComment]: recentComments.length
      ? recentComments[0]!.text
      : '',
    [ColumnKey.lastCommentDate]: recentComments.length
      ? new Date(recentComments[0]!.time)
      : '',
  }
}

type TaskGridRowModel = ReturnType<typeof taskToRow>
const _emtpyRows: TaskGridRowModel[] = []
const defaultVisibleColumns = [
  ColumnKey.selection,
  ColumnKey.type,
  ColumnKey.patient,
  ColumnKey.description,
  ColumnKey.status,
  ColumnKey.createdBy,
  ColumnKey.createdDate,
  ColumnKey.assignee,
  ColumnKey.dueDate,
  ColumnKey.actions,
]

const defaultGridState: GridInitialState = {
  columns: {
    columnVisibilityModel: Object.fromEntries(
      Object.values(ColumnKey).map((v) => [
        v,
        defaultVisibleColumns.includes(v),
      ]),
    ),
  },
  sorting: {
    sortModel: [{ field: ColumnKey.dueDate, sort: 'asc' }],
  },
  filter: {
    filterModel: {
      items: [
        {
          field: ColumnKey.status,
          operator: 'isAnyOf',
          value: [
            {
              value: TaskStatus.Overdue,
              label: T_LABELS.task.taskStatus[TaskStatus.Overdue],
            },
            {
              value: TaskStatus.ToDo,
              label: T_LABELS.task.taskStatus[TaskStatus.ToDo],
            },
          ],
        },
      ],
    },
  },
}

const defaultAggregationInput: Record<
  string,
  AggregationsAggregationContainer
> = {
  assigneeAggs: {
    terms: { field: 'assignee.display.keyword', size: 10000 },
  },
  reportedAggs: {
    terms: { field: 'createdBy.display.keyword', size: 10000 },
  },
}

const defaultGetAggregationInput = {
  query: filterOldTasks,
  aggregations: defaultAggregationInput,
}

function TaskSearchGrid() {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const notify = useNotify()
  const [rowSelectionModel, setSelectionModel] =
    useState<GridRowSelectionModel>([])

  const aggregations = useGetEnhancedSearchTaskAggregatesQuery(
    defaultGetAggregationInput,
  )

  const [assigneeOptionList, reportedOptionList] = useMemo(() => {
    const data = aggregations.data as AggsResultType | undefined
    const assigneeOptionList = data?.assigneeAggs.buckets
      .map((b) => b.key)
      .filter((i) => i !== '')
      .sort((a, b) => a.localeCompare(b))
    const reportedOptionList = data?.reportedAggs.buckets
      .map((b) => b.key)
      .filter((i) => i !== '')
      .sort((a, b) => a.localeCompare(b))
    return [assigneeOptionList, reportedOptionList]
  }, [aggregations.data])

  const { referralSources } = referenceDataApi.useGetReferralSourcesQuery(
    undefined,
    {
      selectFromResult: ({ data }) => ({ referralSources: data }),
    },
  )

  const referralSourceMap = useMemo(() => {
    return Object.fromEntries(
      (referralSources || []).map(({ label, value }) => [value, label]),
    )
  }, [referralSources])

  const columns: ServerSideColumn<TaskGridRowModel>[] = useMemo(() => {
    const _cols: ServerSideColumn<TaskGridRowModel>[] = [
      {
        field: ColumnKey.patient,
        headerName: 'Patient Name',
        osFieldKey: 'patient.display',
        flex: 1.5,
        minWidth: 150,
        valueGetter: (
          params: GridValueGetterParams<
            TaskGridRowModel,
            TaskGridRowModel[ColumnKey.patient]
          >,
        ) => patientStr(params.value),
        renderCell: (
          params: GridRenderCellParams<
            TaskGridRowModel,
            TaskGridRowModel[ColumnKey.patient]
          >,
        ) => (
          <HoverFullContentCell
            width={params.colDef.computedWidth}
            value={
              <Box
                display="flex"
                flexDirection="column"
                onClick={(e) => e.stopPropagation()}
              >
                <Link
                  underline="none"
                  to={`/caseload/treatment/${params.row.treatmentId}/basic-info`}
                  sx={{
                    color: (theme) => theme.palette.secondary.dark,
                  }}
                >
                  {params.row.patient.display}
                </Link>
                <Typography
                  sx={{
                    fontSize: (theme) => theme.typography.body2,
                    opacity: 0.5,
                  }}
                >
                  MRN: {params.row.patient.mrn}
                </Typography>
              </Box>
            }
          />
        ),
      },
      {
        type: 'singleSelect',
        field: ColumnKey.type,
        headerName: 'Task Type',
        flex: 1,
        minWidth: 100,
        valueOptions: Object.values(TaskType).map((type) => {
          return {
            value: type,
            label: t(`task.taskType.${type}`),
          }
        }),
        valueFormatter: (
          params: GridValueFormatterParams<TaskGridRowModel[ColumnKey.type]>,
        ) => {
          return t(`task.taskType.${params.value}`)
        },
        renderCell: renderHoverFullContentCell,
      },
      {
        type: 'string',
        field: ColumnKey.description,
        headerName: 'Task',
        filterOperators: getOsGridStringOperators().filter(
          (op) =>
            !(
              [
                OperatorValueStr.startsWith,
                OperatorValueStr.equals,
                OperatorValueStr.isAnyOf,
              ] as string[]
            ).includes(op.value),
        ),
        flex: 2,
        minWidth: 200,
        renderCell: renderHoverFullContentCell,
        filterItemToOSParam: (item: GridFilterItem, field: string) => {
          if (item.operator === OperatorValueStr.contains) {
            return typeof item.value === 'string' && item.value
              ? {
                  match_phrase_prefix: {
                    [field]: {
                      query: item.value,
                    },
                  },
                }
              : undefined
          }
          return handleStringOsFilter(item, field)
        },
      },
      {
        type: 'singleSelect',
        field: ColumnKey.status,
        headerName: 'Status',
        width: 115,
        minWidth: 75,
        sortable: false,
        filterItemToOSParam: handleTaskStatusFilter,
        filterOperators: getOsGridSingleSelectOperators().filter(({ value }) =>
          (
            [
              OperatorValueSingleSelect.is,
              OperatorValueSingleSelect.not,
              OperatorValueSingleSelect.isAnyOf,
            ] as string[]
          ).includes(value),
        ),
        renderCell: (
          params: GridRenderCellParams<
            TaskGridRowModel,
            TaskGridRowModel[ColumnKey.status]
          >,
        ) => (
          <TaskStatusChip status={params.value!} dueDate={params.row.dueDate} />
        ),
        valueGetter: (
          params: GridValueGetterParams<
            TaskGridRowModel,
            TaskGridRowModel[ColumnKey.status]
          >,
        ) =>
          params.value === TaskStatus.ToDo &&
          params.row.dueDate &&
          isPast(params.row.dueDate)
            ? TaskStatus.Overdue
            : params.value,
        valueOptions: () =>
          [
            TaskStatus.Overdue,
            TaskStatus.ToDo,
            TaskStatus.Completed,
            TaskStatus.Void,
          ].map((v) => {
            return {
              value: v,
              label: t(`task.taskStatus.${v}`),
            }
          }),
        valueFormatter: ({ value }: GridValueFormatterParams<string>) =>
          t(`task.taskStatus.${value}`),
      },
      {
        type: 'singleSelect',
        field: ColumnKey.serviceType,
        headerName: 'Service Type',
        flex: 1,
        minWidth: 100,
        valueOptions: Object.values(TaskServiceType).map((type) => {
          return {
            value: type as TaskServiceType,
            label: t(`task.taskServiceType.${type}`) as string,
          }
        }),
        valueFormatter: (
          params: GridValueFormatterParams<string | undefined>,
        ) => {
          return params?.value ? t(`task.taskServiceType.${params?.value}`) : ''
        },
        renderCell: renderHoverFullContentCell,
      },
      {
        type: 'singleSelect',
        field: ColumnKey.referralSource,
        headerName: 'Referral Source',
        flex: 1.5,
        minWidth: 150,
        valueOptions: referralSources || [],
        valueFormatter: ({ value }: GridValueFormatterParams<string>) =>
          referralSourceMap[value] ?? value,
        renderCell: renderHoverFullContentCell,
      },
      {
        type: 'singleSelect',
        field: ColumnKey.createdBy,
        headerName: 'Reported',
        osFieldKey: 'createdBy.display',
        flex: 1,
        minWidth: 120,
        valueOptions: reportedOptionList || [],
        valueGetter: (
          params: GridValueGetterParams<
            TaskGridRowModel[ColumnKey.createdBy],
            TaskGridRowModel
          >,
        ) => referenceStr(params.value || {}),
        renderCell: (
          params: GridRenderCellParams<
            TaskGridRowModel,
            TaskGridRowModel[ColumnKey.createdBy]
          >,
        ) => (
          <ReferenceGridCell
            {...params.row.createdBy}
            computedWidth={params.colDef.computedWidth}
          />
        ),
      },
      {
        type: 'dateTime',
        field: ColumnKey.createdDate,
        headerName: 'Added',
        flex: 1.5,
        minWidth: 100,
        valueFormatter: ({
          value,
        }: GridValueFormatterParams<Date | null | undefined>) =>
          value && format(value, 'PPp'),
        renderCell: renderHoverFullContentCell,
      },
      {
        type: 'singleSelect',
        field: ColumnKey.assignee,
        headerName: 'Assigned',
        osFieldKey: 'assignee.display',
        flex: 1,
        minWidth: 120,
        valueOptions: assigneeOptionList || [],
        valueGetter: (
          params: GridValueGetterParams<
            TaskGridRowModel[ColumnKey.assignee],
            TaskGridRowModel
          >,
        ) => referenceStr(params.value || {}),
        renderCell: (
          params: GridRenderCellParams<
            TaskGridRowModel,
            TaskGridRowModel[ColumnKey.assignee]
          >,
        ) => (
          <ReferenceGridCell
            {...params.row.assignee}
            computedWidth={params.colDef.computedWidth}
          />
        ),
      },
      {
        type: 'date',
        field: ColumnKey.dueDate,
        headerName: 'Due Date',
        flex: 1,
        minWidth: 100,
        valueFormatter: ({
          value,
        }: GridValueFormatterParams<Date | null | undefined>) =>
          value && format(value, 'PP'),
        renderCell: renderHoverFullContentCell,
      },
      {
        type: 'string',
        field: ColumnKey.lastComment,
        headerName: 'Last Comment',
        osFieldKey: 'recentComments.text',
        filterOperators: getOsGridStringOperators().filter(
          (op) =>
            !(
              [
                OperatorValueStr.startsWith,
                OperatorValueStr.equals,
                OperatorValueStr.isAnyOf,
              ] as string[]
            ).includes(op.value),
        ),
        flex: 2,
        minWidth: 200,
        renderCell: renderHoverFullContentCell,
      },
      {
        type: 'date',
        field: ColumnKey.lastCommentDate,
        osFieldKey: 'recentComments.time',
        headerName: 'Last Comment Date',
        flex: 1,
        minWidth: 100,
        valueFormatter: ({
          value,
        }: GridValueFormatterParams<Date | null | undefined>) =>
          value && format(value, 'PP'),
        renderCell: renderHoverFullContentCell,
      },
      {
        field: ColumnKey.actions,
        headerName: 'Actions',
        width: 110,
        minWidth: 110,
        type: 'actions',
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<TaskGridRowModel>) => {
          const task = params.row._task
          return (
            <Box
              onClick={(e) => e.stopPropagation()}
              sx={{ display: 'flex', flexWrap: 'nowrap' }}
            >
              <IconButton
                title="Edit"
                onClick={() =>
                  navigate(`./edit/${task._id}`, {
                    state: { task },
                  })
                }
                sx={{ mr: 1 }}
              >
                <EditIcon />
              </IconButton>
              <DeleteButton task={task} />
            </Box>
          )
        },
      },
    ]
    return [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        headerName: 'Row Selections',
      },
      ..._cols,
    ]
  }, [
    referralSources,
    reportedOptionList,
    assigneeOptionList,
    t,
    referralSourceMap,
    navigate,
  ])

  const initialState = usePersistentGridInitialState(
    window.location.pathname,
    defaultGridState,
  )

  const { gridProps, queryProps } = useServerSideGrid(
    columns,
    initialState,
    quickFilterFields,
    defaultQuickFilterSortOverride,
  )

  const args = useMemo(() => {
    return {
      ...queryProps,
      query: {
        ...queryProps.query,
        ...filterOldTasks,
      },
      sort: queryProps.sort.concat({ 'id.keyword': 'asc' }),
    }
  }, [queryProps])

  const { tasks, resultCount, error, isFetching } = useSearchTasksQuery(args, {
    pollingInterval: process.env.IS_LOCAL ? undefined : 120000, // 2min
    selectFromResult: ({ data, isFetching, error }) => ({
      tasks: data?.searchTasks.tasks,
      resultCount: data?.searchTasks.resultCount || 0,
      isFetching,
      error,
    }),
  })

  useEffect(() => {
    if (error) {
      notify({
        message: error.message || 'An error occured loading tasks',
        severity: 'error',
      })
    }
  }, [notify, error])

  const rows = useMemo(
    (): TaskGridRowModel[] => tasks?.map(taskToRow) || _emtpyRows,
    [tasks],
  )

  return (
    <PersistentDataGrid
      {...gridProps}
      name={window.location.pathname}
      defaultGridState={defaultGridState}
      density="compact"
      loading={isFetching}
      rows={rows}
      onRowClick={(params) => {
        navigate(`./view/${params.id}${window.location.search || ''}`)
      }}
      rowCount={resultCount}
      checkboxSelection
      disableRowSelectionOnClick
      rowSelectionModel={rowSelectionModel}
      onRowSelectionModelChange={(newSelectionModel) => {
        setSelectionModel(newSelectionModel)
      }}
      slotProps={{
        toolbar: {
          children: (
            <>
              <Button
                sx={{ order: 1000 }}
                onClick={() =>
                  navigate(
                    `./bulkupdate/${rowSelectionModel.join(',')}${
                      window.location.search || ''
                    }`,
                  )
                }
                disabled={!rowSelectionModel.length}
              >
                {t('bulk update')}
              </Button>
              <Button
                startIcon={<Add />}
                sx={{
                  order: 1000,
                }}
                onClick={() => navigate(`./add${window.location.search || ''}`)}
              >
                {t('add')}
              </Button>
            </>
          ),
        },
      }}
      getRowHeight={() => 'auto'}
    />
  )
}

export default TaskSearchGrid
