import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { TaskFragment, taskApi } from '@valerahealth/rtk-query'
import {
  Button,
  Grid,
  Stack,
  Typography,
  useNotify,
} from '@valerahealth/ui-components'
import {
  FormProvider,
  ReferralSourceSelect,
  SaveButton,
  useForm,
} from '@valerahealth/ui-components/form'
import { dateToISODateStr } from '@valerahealth/ui-components/utils/date'
import { useTranslation } from '@valerahealth/ui-translation'
import { isWellnessSelector } from 'redux/selectors'
import {
  AssignedCombobox,
  ColumnKey,
  CommentWriteTextField,
  DueDatePicker,
  ServiceTypeWriteCombobox,
} from 'components/TaskForm'

const gridSpacing = 1.5

export interface BulkUpdateFormInput {
  onCancel: () => void
  onSaveSuccess: () => void
}

export interface BulkUpdateFormType {
  [ColumnKey.serviceType]: TaskFragment['serviceType'] | null
  [ColumnKey.referralSource]: TaskFragment['referralSource'] | null
  [ColumnKey.assignee]: TaskFragment['assignee'] | null
  [ColumnKey.dueDate]: Date | null
  [ColumnKey.comment]: string
}

export type SubmittedBulkUpdateFormType = BulkUpdateFormType

const defaultValues: BulkUpdateFormType = {
  [ColumnKey.serviceType]: null,
  [ColumnKey.referralSource]: null,
  [ColumnKey.assignee]: null,
  [ColumnKey.dueDate]: null,
  [ColumnKey.comment]: '',
}

export function BulkUpdateForm({
  onCancel,
  onSaveSuccess,
}: BulkUpdateFormInput) {
  const { t } = useTranslation()
  const notify = useNotify()
  const [updateTasksMutation, updateRes] = taskApi.useUpdateTasksMutation()
  const isWellness = useSelector(isWellnessSelector)
  const methods = useForm<BulkUpdateFormType>({
    defaultValues,
  })

  const ids = useParams<{ ids: string }>().ids?.split(',') || []

  const onFormSubmit = async (fields: SubmittedBulkUpdateFormType) => {
    const res = await updateTasksMutation({
      content: ids.map((id) => ({
        id,
        serviceType: fields.serviceType || undefined,
        referralSource: fields.referralSource || undefined,
        assignee: fields.assignee?._id || undefined,
        dueDate: fields.dueDate ? dateToISODateStr(fields.dueDate) : undefined,
        comment: fields.comment || undefined,
      })),
    })
    if ('error' in res) {
      notify({
        message: res.error.message || t('networkError.500'),
        severity: 'error',
      })
      return
    }
    methods.reset(defaultValues)
    notify({
      message: t('task.success_tasks_update'),
      severity: 'success',
    })
    onSaveSuccess()
  }
  const handleCancel = () => {
    onCancel()
    methods.reset({})
  }
  return (
    <Grid
      container
      spacing={gridSpacing}
      component="form"
      onSubmit={methods.handleSubmit((values) =>
        onFormSubmit(values as SubmittedBulkUpdateFormType),
      )}
      sx={{ pt: 1 }}
    >
      <FormProvider {...methods}>
        <Grid item xs={12}>
          {!!ids.length && (
            <Typography sx={{ lineHeight: '2' }}>
              {ids.length === 1
                ? 'Updates will apply to 1 task selected.'
                : `Updates will apply to ${ids.length} tasks selected.`}
            </Typography>
          )}
        </Grid>
        {isWellness && (
          <Grid item xs={6}>
            <ServiceTypeWriteCombobox />
          </Grid>
        )}
        <Grid item xs={6}>
          <ReferralSourceSelect
            label={t('Referral Source')}
            name={ColumnKey.referralSource}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={6}>
          <AssignedCombobox
            required={false}
            enableDefualtAssignToSelf={false}
          />
        </Grid>
        <Grid item xs={6}>
          <DueDatePicker required={false} />
        </Grid>
        <Grid item xs={12}>
          <CommentWriteTextField />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" gap={gridSpacing}>
            <Button
              variant="outlined"
              color="error"
              sx={{ ml: 'auto' }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <SaveButton
              disabled={!ids.length}
              isError={updateRes.isError}
              isSuccess={updateRes.isSuccess}
            />
          </Stack>
        </Grid>
      </FormProvider>
    </Grid>
  )
}
