/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import { isPast, strToDate } from '@valerahealth/ui-components/utils/date'
import { Badge, Box } from '@valerahealth/ui-components'
import { searchTasksQueryforAssignee, taskApi } from '@valerahealth/rtk-query'

const { useSearchTasksQuery } = taskApi

export const TaskNotification = ({ userId }: { userId: string }) => {
  const query = searchTasksQueryforAssignee(userId)
  const { data, isSuccess } = useSearchTasksQuery(
    {
      query,
      pageSize: 10000,
    },
    { pollingInterval: process.env.IS_LOCAL ? undefined : 60000 },
  )

  const tasks = data?.searchTasks?.tasks
  const numOverdue =
    tasks?.filter((t) => {
      const date = strToDate(t.dueDate, true)
      if (!date) return false
      if (isPast(date)) return true
      return false
    }).length || 0
  const numTodo = (tasks?.length || 0) - numOverdue
  return isSuccess ? (
    <>
      <Box display="flex" height="80%">
        {numOverdue !== 0 && (
          <Badge badgeContent={numOverdue} color="error">
            <Box width="10px" height="10px" />
          </Badge>
        )}
        {numTodo !== 0 && (
          <Badge badgeContent={numTodo} color="warning">
            <Box width="20px" height="10px" />
          </Badge>
        )}
      </Box>
    </>
  ) : null
}
