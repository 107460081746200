export type MedicalDiagnosis = {
  code: string
  name: string
}

type FieldGroupType = 'single_select_free_text'

export type FieldGroup = {
  itemType: 'field_group'
  // title: string, this is returned by the api but its emtpy and we've never coded against it so may just be baggage
  type: FieldGroupType
  fields: Field[]
}

export type SectionItem = Field | FieldGroup | TemplateSubSection

type BaseField = {
  itemType: 'field'
  id: string
  name: string
  required: boolean
  placeholder?: string | null
}
export type Field = BaseField &
  (
    | {
        type: 'single_select'
        options: string[] | number[]
        value: string | number | null
      }
    | {
        type: 'multi_select'
        options: string[] | number[]
        value: string[] | number[] | null
      }
    | {
        type: 'free_text'
        value: string | null
      }
    | {
        type: 'check_box' | 'yes_no'
        value: boolean | null
      }
    | {
        type: 'date_picker'
        // ISO Date Time
        value: string | null
      }
  )

export type TemplateSection = {
  itemType: 'section'
  id: string
  title: string
  required: boolean
  include: boolean
  items: SectionItem[]
  code?: string
}

export type TemplateSubSection = Omit<TemplateSection, 'items'> & {
  items: Array<Field | FieldGroup>
}

export type TemplateNote = {
  id: string
  name: string
  sections: TemplateSection[]
}

export type TemplatesOverview = {
  id: string
  name: string
}

export type LinkedEvent = {
  domainEvent: string
  eventId: string
  treatmentHistoryId: string
  // info: object - Dont know what to pass and we dont use it so commenting out for now
  // iso date time
  ts: string
}

export enum ClinicalNoteType {
  Progress = 'progress',
  Psych = 'psychotherapy',
}

export type ClinicalNoteBase = {
  id: string
  // ID of care manager
  createdBy: string
  // ISO date time
  createdAt: string
  status: 'draft' | 'published'
  date: string
  history: {
    type: 'created' | 'edited'
    // iso date time
    ts: string
    // ID of care manager
    by: string
  }[]
  linkedEvents?: {
    domainEvent: string
    eventId: string
    treatmentHistoryId: string
    // iso date time
    ts: string
  }[]
  appointmentId?: string
  saveSessionId?: string
}

export type PsychNotePart = {
  type: ClinicalNoteType.Psych
  title: string
  contentText: string
  content: string
  contentType: string
}

export type ProgressNoteSectionTemplate = TemplateSection & {
  templateId: string
}

export type ProgressNotePart = {
  type: ClinicalNoteType.Progress
  title?: string | null
  templatesOverview: TemplatesOverview[]
  sections: ProgressNoteSectionTemplate[]
  medicalDiagnosis: MedicalDiagnosis[]
}

export type PsychNote = ClinicalNoteBase & PsychNotePart
export type ProgressNote = ClinicalNoteBase & ProgressNotePart

export type ClinicalNote = PsychNote | ProgressNote

export type CreateClinicalNoteBase = {
  // iso date time
  date: string
  isDraft: boolean
  linkedEvents?: LinkedEvent[]
  appointmentId?: string
}

export type CreateProgressNotePart = {
  type: ClinicalNoteType.Progress
  title?: string
}

export type CreatePsychNotePart = {
  title: string
  type: ClinicalNoteType.Psych
  content: string
  contentText: string
  contentType: string
}

export type CreateProgressNote = CreateClinicalNoteBase & CreateProgressNotePart

export type CreatePsychNote = CreateClinicalNoteBase & CreatePsychNotePart

export type CreateClinicalNote = CreateProgressNote | CreatePsychNote

export type UpdateClinicalNoteBase = Pick<CreateClinicalNoteBase, 'isDraft'> &
  Partial<Omit<CreateClinicalNoteBase, 'isDraft'>>

export type UpdatePsychNote = UpdateClinicalNoteBase &
  Partial<CreatePsychNotePart>

export type UpdateProgressNote = UpdateClinicalNoteBase &
  Partial<CreateProgressNotePart> & {
    // this may be a backend schema bug but they only have this field on the update joi validation
    medicalDiagnosis?: MedicalDiagnosis[]
  }

export type UpdateClinicalNote = UpdatePsychNote | UpdateProgressNote

export type TemplateData = {
  templateIds: {
    add: string[]
    remove: string[]
  }
  saveSessionId?: string
  isDraft: boolean
}

export type AnswerOnFieldData = {
  fieldId: string
  templateId: string
  value: string | number | boolean | string[] | number[] | null
  isDraft: boolean
  saveSessionId?: string
}

export type IncludeSectionData = {
  sections: { sectionId: string; include: boolean; templateId?: string }[]
  saveSessionId?: string
  isDraft: boolean
}
